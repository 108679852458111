import { Box, Grid, Typography } from "@mui/material";
import ProductCard from "./components/ProductCard/ProductCard";
import { useEffect, useState } from "react";
import axios from "axios";
import Filters from "./components/Filters";
import { API_URL } from "../../api";
import CircularLoaderWithLogo from "../../components/Loader/Loader";
import { useLocation, useSearchParams } from "react-router-dom";

const ProductsPage = () => {
  const [mobGrid, setMobGrid] = useState(12);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");

  useEffect(() => {
    setLoading(true);
    const categoryId = category === "women" ? 2 : category === "men" ? 1 : "";
    const url = categoryId
      ? `/products/category/${categoryId}/products`
      : "/products";

    axios
      .get(`${API_URL}${url}`)
      .then((response) => {
        setLoading(false);
        setProducts(response.data);
        setFilteredData(response.data);
      })
      .catch();
  }, [category]);

  useEffect(() => {
    document.title = "SKRBS - Collection";
    return () => {
      document.title = "SKRBS";
    };
  }, []);
  return loading ? (
    <Box
      style={{
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularLoaderWithLogo />
    </Box>
  ) : (
    <Box
      sx={{
        pl: {
          xs: 1,
          md: 10,
        },
        pr: {
          xs: 1,
          md: 10,
        },
      }}
      style={{ paddingTop: 25 }}
    >
      <Typography style={{ fontSize: 30, marginBottom: 15 }}>
        {category === "women"
          ? "Women's collection"
          : category === "men"
          ? "Men's collection"
          : ""}
      </Typography>
      <Filters
        setMobGrid={setMobGrid}
        mobGrid={mobGrid}
        setFilteredData={setFilteredData}
        products={products}
      />
      <Grid
        container
        spacing={2}
        direction="row"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {filteredData.map((product, index) => (
          <Grid item key={index} xs={mobGrid} lg={3}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default ProductsPage;
