// CarouselComponent.js
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

const Carousel = ({
  slidesToShow,
  slidesToScroll,
  sliderContentData,
  sliderContent,
  infinite = false,
}) => {
  const settings = {
    infinite: infinite,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
  };
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleBeforeChange = (current, next) => {
    setCurrentSlide(next);
  };

  useEffect(() => {
    const sliderContainer = document.querySelector(".your-slider-container");
    if (sliderContainer) {
      const activeSlide = sliderContainer.querySelector(".slick-current");
      if (activeSlide) {
        setCurrentSlide(parseInt(activeSlide.getAttribute("data-index"), 10));
      }
    }
  }, [sliderContentData, currentSlide]);

  return (
    <div>
      <div className="your-slider-container">
        <Slider
          {...settings}
          beforeChange={handleBeforeChange}
          className="custom-slider"
          style={{ padding: 0, margin: 0 }}
        >
          {sliderContent}
        </Slider>
      </div>
      <div
        className={`pagination-line slides-to-show-${settings.slidesToShow}`}
      >
        {[
          ...Array(
            Math.ceil(sliderContentData.length / settings.slidesToScroll)
          ),
        ].map((_, index) => (
          <span
            key={index}
            className={`pagination-part ${
              currentSlide >= index * settings.slidesToScroll &&
              currentSlide < (index + 1) * settings.slidesToScroll
                ? "active"
                : ""
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
