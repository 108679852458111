import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Form, Formik } from "formik";
import FormField from "../../../../../components/FormField";
import axios from "axios";
import { API_URL } from "../../../../../api";
import { Box, Button, CircularProgress } from "@mui/material";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};

const AddCategory = ({ refetch, setOpenAddCategory }) => {
  const [loading, setLoading] = useState(false);
  // const [msg, setMessage] = useState(false);
  const [error, setError] = useState("");

  const initialValues = useMemo(
    () => ({
      name: "",
    }),
    []
  );

  const handleAddCategory = async (event, values) => {
    setLoading(true);
    setError("");
    axios
      .post(`${API_URL}/products/category`, values, {})
      .then(() => {
        setLoading(false);
        setOpenAddCategory(false);
        refetch();
      })
      .catch((err) => {
        setLoading(false);
        err.message == "Network Error"
          ? setError(
              "Failed to Save ! Your images might be too large, compress them and try again."
            )
          : setError("Failed to Save !");
      });
  };
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 50,
        width: "100%",
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values }) => (
          <Form>
            <Typography
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Add Category
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`name`}
                  label="Name"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  required
                />
              </Grid>
            </Grid>
            {error && (
              <Typography
                style={{ marginTop: 3, marginBottom: 3, color: "red" }}
              >
                {error}
              </Typography>
            )}
            <Button
              style={{ background: "black", color: "white", marginTop: 30 }}
              fullWidth
              onClick={(event) => handleAddCategory(event, values)}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                "Save"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default AddCategory;
