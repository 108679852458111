import { Grid, Typography } from "@mui/material";
import GridRecord from "../GridRecord/GridRecord";

const ProductRecord = ({ product }) => {
  console.log(
    "testttt",
    product?.product.sale_price > 0
      ? product?.product?.sale_price
      : product.product.price
  );
  return (
    <>
      <GridRecord
        primaryText={product.product.name}
        secondaryText={
          "LE " + product?.product.sale_price > 0
            ? product?.product?.sale_price
            : product.product.price
        }
        primaryFontWeight={700}
        secondaryFontWeight={700}
        marginBottom={0}
      />
      <Grid container style={{ marginBottom: product?.customization ? 0 : 20 }}>
        <Grid item xs={4}>
          <Typography
            sx={{ fontSize: { xs: 12, md: 15 } }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#282828",
            }}
          >
            QTY:{product.quantity}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{ fontSize: { xs: 12, md: 15 } }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#282828",
            }}
          >
            COLOR:{product.color}
          </Typography>
        </Grid>
      </Grid>
      {product?.customization && (
        <Grid container style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Typography
              sx={{ fontSize: { xs: 12, md: 15 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "#282828",
              }}
            >
              EMBROIDERY:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{ fontSize: { xs: 12, md: 15 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "#282828",
              }}
            >
              Text: {product?.customization?.text}
            </Typography>

            <Typography
              sx={{ fontSize: { xs: 12, md: 15 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "#282828",
              }}
            >
              Title: {product?.customization?.title}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography
              sx={{ fontSize: { xs: 12, md: 15 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "#282828",
              }}
            >
              Color:{product?.customization?.color}
            </Typography>
            <Typography
              sx={{ fontSize: { xs: 12, md: 15 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "#282828",
              }}
            >
              Placement: {product?.customization?.placement}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default ProductRecord;
