import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { API_URL } from "../../../../api";

import axios from "axios";

const UserOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const jsonString = localStorage.getItem("currentUser");
  const currentUser = jsonString && JSON.parse(jsonString);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/orders/user/${currentUser?.id}`)
      .then((response) => {
        setLoading(false);
        setOrders(response.data);
      })
      .catch(setLoading(false));
  }, []);

  return loading ? (
    <Box
      style={{
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress
        style={{
          color: "black",
        }}
      />
    </Box>
  ) : (
    <Box sx={{ ml: { md: 20 } }}>
      <Grid container>
        {orders.map((order) => {
          return (
            <Grid item xs={12}>
              <Box
                style={{ display: "flex", flexDirection: "row", columnGap: 10 }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    color: "#282828",
                    fontSize: 20,
                  }}
                >
                  Order number :
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    color: "#282828",
                    fontSize: 20,
                  }}
                >
                  #{order?.order_number}
                </Typography>
              </Box>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontFamily: "Montserrat",
                  marginBottom: 10,
                }}
              >
                {order?.status}
              </Typography>
              {order?.items?.map((item) => {
                return (
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <img
                        src={item?.image?.image}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          backgroundColor: "#ECECEC",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ aligItems: "center" }}>
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 15 },
                          marginTop: { md: "10%" },
                        }}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                          color: "#282828",
                        }}
                      >
                        {item.product.name.toUpperCase()}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          color: "#282828",
                        }}
                      >
                        {item.color}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          color: "#282828",
                        }}
                      >
                        X {item.quantity}
                      </Typography>{" "}
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          color: "#282828",
                        }}
                      >
                        {item.size}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 15 },
                          marginTop: { md: "10%" },
                        }}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                          color: "#282828",
                        }}
                      >
                        EMBROIDERY:
                      </Typography>
                      {item?.customization ? (
                        <>
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              color: "#282828",
                            }}
                          >
                            Text: {item.customization?.text}
                          </Typography>{" "}
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              color: "#282828",
                            }}
                          >
                            Title: {item.customization?.title}
                          </Typography>{" "}
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              color: "#282828",
                            }}
                          >
                            Font: {item.customization?.font}
                          </Typography>{" "}
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              color: "#282828",
                            }}
                          >
                            Color: {item.customization?.color}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              color: "#282828",
                            }}
                          >
                            Placement: {item.customization?.placement}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            color: "#282828",
                          }}
                        >
                          None
                        </Typography>
                      )}
                    </Grid>

                    <Divider style={{ marginTop: 10 }} />
                  </Grid>
                );
              })}
              <Divider style={{ margin: "30px 40px 30px 0px" }} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default UserOrders;
