import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { API_URL } from "../../../api";
import axios from "axios";
const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};
const SigninForm = () => {
  const [loading, setLoading] = useState();
  const [err, setError] = useState("");
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const redirect = queryParams.get("redirect");

  const handleSubmit = (event) => {
    setLoading(true);

    setError("");
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const values = {
      email: data.get("email"),
      password: data.get("password"),
    };
    axios
      .post(`${API_URL}/users/login`, values)
      .then((response) => {
        setLoading(false);
        const jsonString = JSON.stringify(response.data);
        localStorage.setItem("currentUser", jsonString);
        redirect ? navigate(`/${redirect}`) : navigate("/profile");
      })
      .catch((e) => {
        setLoading(false);
        setError("Invalid email or password");
      });
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: { xs: 5, md: 20 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontSize: { xs: 20, md: 30 } }}
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: " #28282",
          }}
        >
          Welcome Back!
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            sx={customTextFieldStyling}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            sx={customTextFieldStyling}
          />
          <Grid container>
            <Grid item xs>
              <Link
                  href="/user/send-reset-password"
                  variant="body2"
                style={{ color: "black", textDecorationColor: "black" }}
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "black", color: "white" }}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <CircularProgress style={{ color: "white" }} />
            ) : (
              " Sign In"
            )}
          </Button>
          {err && <Typography style={{ color: "red" }}>{err}</Typography>}

          <Divider sx={{ mt: 4, mb: 4 }} />
          <Typography
            sx={{ fontSize: { xs: 15, md: 20 } }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              color: "#282828",
              marginBottom: 20,
            }}
          >
            New to SKRBS?{" "}
            <span
              style={{
                fontFamily: "Montserrat",
                fontWeight: 300,
                color: "#282828",
                cursor: "pointer",
              }}
              onClick={() => navigate("/signup")}
            >
              Create an account
            </span>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
export default SigninForm;
