import { Box, Divider, Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const ReadMoreSection = ({ section, sub = false }) => {
  const [readMore, setReadMore] = useState(false);
  const handleOnClick = useCallback(() => {
    setReadMore(!readMore);
  }, [readMore, setReadMore]);
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
        marginTop: 10,
        marginBotoom: 10,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography
            sx={{ fontSize: { xs: 17, md: 20 } }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: sub ? 500 : 600,
              color: "#282828",
            }}
          >
            {section.title}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {!readMore ? (
            <AddIcon onClick={handleOnClick} style={{ cursor: "pointer" }} />
          ) : (
            <RemoveIcon onClick={handleOnClick} style={{ cursor: "pointer" }} />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={11}>
          {readMore && (
            <Typography
              sx={{ fontSize: { xs: sub ? 14 : 16, md: sub ? 19 : 20 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "#282828",
              }}
            >
              {section.description}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};
export default ReadMoreSection;
