import { Box, Typography } from "@mui/material";

const TextWithDifferentColors = ({
  firstText,
  firstTextColor,
  secondText,
  secondTextColor,
}) => {
  return (
    <Box sx={{ padding: { xs: "10px 30px", md: "30px 170px" } }}>
      <Typography
        sx={{ fontSize: { xs: 18, md: 25 }, textAlign: { md: "center" } }}
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          color: secondTextColor,
        }}
      >
        <span
          sx={{ fontSize: { xs: 18, md: 25 } }}
          style={{
            fontFamily: "Montserrat",
            fontWeight: 700,
            color: firstTextColor,
          }}
        >
          {firstText}{" "}
        </span>
        {secondText}
      </Typography>
    </Box>
  );
};
export default TextWithDifferentColors;
