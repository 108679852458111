import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Layout from "./components/Layout";
import Homepage from "./pages/Homepage";
import AboutUsPage from "./pages/AboutUs";
import ProductsPage from "./pages/Products";
import ProductDetails from "./pages/ProductDetails";
import Checkout from "./pages/Checkout";
import ConfirmedOrder from "./pages/ConfirmedOrder";
import AdminSignIn from "./pages/AdminPortal/AdminSignIn";
import AdminLayout from "./components/AdminLayout/Adminlayout";
import Orders from "./pages/AdminPortal/Orders";
import Users from "./pages/AdminPortal/Users/Users";
import Employees from "./pages/AdminPortal/Employees";
import Profile from "./pages/AdminPortal/Profile/Profile";
import Dashboard from "./pages/AdminPortal/Dashboard";
import FAQ from "./pages/FAQ";
import Signin from "./pages/Signin/Signin";
import Signup from "./pages/Signup";
import Cart from "./pages/Cart";
import ContactUs from "./pages/ContactUs/ContactUs";
import ShippingFees from "./pages/AdminPortal/ShippingFees";
import UserProfile from "./pages/Profile";
import FeaturedProducts from "./pages/AdminPortal/FeaturedProducts";
import Products from "./pages/AdminPortal/Products/Products";
import Categories from "./pages/AdminPortal/Categories/Categories";
import SubCategories from "./pages/AdminPortal/SubCategories/SubCategories";

import ResetPasswordForm from "./pages/AdminPortal/ResetPasswordForm/ResetPasswordForm";
import ResetPassword from "./pages/AdminPortal/ResetPassword/ResetPassword";
import UserResetPasswordForm from "./pages/UserResetPasswordForm";
import UserResetPassword from "./pages/UserResetPassword";
import Promocodes from "./pages/AdminPortal/Promocodes";
import SaleProducts from "./pages/AdminPortal/SaleProducts";
import SizeChart from "./components/SizeChart";

const App = () => {
  return (
    <MuiThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/product-details" element={<ProductDetails />} />
            <Route path="/product-details/:id" element={<ProductDetails />} />

            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/size-guide" element={<SizeChart isPage={true} />} />

            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route
              path="user/send-reset-password/"
              element={<UserResetPasswordForm />}
            />
            <Route
              path="user/password-reset/:token"
              element={<UserResetPassword />}
            />
            <Route
              path="user/password-reset/:token/:userId"
              element={<UserResetPassword />}
            />
          </Route>
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/confirmedOrder" element={<ConfirmedOrder />} />

          {/* Admin portal routes */}
          <Route path="/admin" element={<AdminSignIn />} />
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="orders" element={<Orders />} />
            <Route path="users" element={<Users />} />
            <Route path="employees" element={<Employees />} />
            <Route path="profile" element={<Profile />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="shippingFees" element={<ShippingFees />} />
            <Route path="featured" element={<FeaturedProducts />} />
            <Route path="products" element={<Products />} />
            <Route path="categories" element={<Categories />} />
            <Route path="subcategories" element={<SubCategories />} />

            <Route path="promocode" element={<Promocodes />} />
            <Route path="sale" element={<SaleProducts />} />
          </Route>
          <Route path="/send-reset-password/" element={<ResetPasswordForm />} />
          <Route path="/password-reset/:token" element={<ResetPassword />} />
          <Route
            path="/password-reset/:token/:userId"
            element={<ResetPassword />}
          />
          <Route path="/" element={<Layout />}>
            {" "}
            <Route path="*" element={<Homepage />} />
          </Route>
        </Routes>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
