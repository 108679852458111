import { Box } from "@mui/material";

const ImageContainer = ({ image, index }) => {
  return (
    <Box>
      <img
        src={image}
        alt={index}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  );
};
export default ImageContainer;
