import { Box, Grid, Typography } from "@mui/material";
import customizeIcon from "../../../../assets/images/customizeIcon.png";
import cottonIcon from "../../../../assets/images/cottonIcon.png";
import supportIcon from "../../../../assets/images/supportIcon.png";

const qualifications = [
  {
    image: customizeIcon,
    title: "Customise your Scrubs",
    description: "Elevate Your Look with Customizable Scrubs!",
  },
  {
    image: cottonIcon,
    title: "Premuim quality",
    description: "70 %cotton and 30 % polyester",
    styling: 14,
  },
  {
    image: supportIcon,
    title: "Online Support",
    description:
      "Support at Your Fingertips: Experience Seamless Online Assistance for All Your Scrub Queries!",
    styling: 10,
  },
];
const ScrubsQualifications = () => {
  return (
    <Grid
      container
      style={{ marginBottom: -70 }}
      sx={{ ml: { xs: 0 }, mr: { xs: 0 }, mt: { xs: 0, md: 5 } }}
    >
      {qualifications.map((qualification) => {
        return (
          <Grid
            item
            xs={12}
            md={4}
            style={{
              backgroundColor: "#ECECEC",
            }}
            sx={{ p: { xs: 3, md: 6 } }}
          >
            <Box>
              <Box
                style={{
                  display: "flex",
                  backgroundColor: "#ECECEC",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom:
                    qualification?.styling && qualification?.styling,
                }}
              >
                <img
                  alt=""
                  src={qualification.image}
                  style={{
                    width: "15%",
                    height: "auto",
                    backgroundColor: "#ECECEC",
                  }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#ECECEC",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: 15,
                    marginBottom: 3,
                  }}
                >
                  {qualification.title}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    fontSize: 13,
                  }}
                  sx={{ pl: { xs: 5, md: 0 }, pr: { xs: 5, md: 0 } }}
                >
                  {qualification.description}
                </Typography>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default ScrubsQualifications;
