import { Box, Typography } from "@mui/material";
import Section from "./components/Section/Section";

const menChart = [
  {
    title: "Jacket Length",
    size1: "68",
    size2: "70",
    size3: "72",
    size4: "74",
    size5: "76",
  },
  {
    title: "Jacket Width",
    size1: "47",
    size2: "51",
    size3: "56",
    size4: "61",
    size5: "67",
  },
  {
    title: "Pants Length",
    size1: "96",
    size2: "100",
    size3: "104",
    size4: "108",
    size5: "112",
  },
  {
    title: "Waist",
    size1: "31",
    size2: "33",
    size3: "35",
    size4: "37",
    size5: "39",
  },
];
const dentalChart = [
  {
    title: "Jacket Length",
    size1: "73",
    size2: "75",
    size3: "77",
    size4: "79",
    size5: "81",
  },
  {
    title: "Jacket Width",
    size1: "51",
    size2: "55",
    size3: "61",
    size4: "65",
    size5: "69",
  },
];
const womenChart = [
  {
    title: "Jacket Length",
    size1: "69",
    size2: "71",
    size3: "74",
    size4: "76",
    size5: "78",
  },
  {
    title: "Jacket Width",
    size1: "48",
    size2: "50",
    size3: "52",
    size4: "52",
    size5: "56",
  },
  {
    title: "Pants Length",
    size1: "98",
    size2: "100",
    size3: "103",
    size4: "105",
    size5: "107",
  },
  {
    title: "Waist",
    size1: "35",
    size2: "37",
    size3: "39",
    size4: "41",
    size5: "43",
  },
];
const menSizes = ["S", "M", "L", "XL", "2XL"];

const womenSizes = ["XS", "S", "M", "L", "XL"];
const SizeChart = ({ isPage }) => {
  return (
    <Box
      sx={{
        paddingLeft: {
          xs: 3,
          md: isPage ? 10 : 3,
        },
        paddingRight: {
          xs: 2,
          md: isPage ? 10 : 3,
        },
        display: "flex",
        flexDirection: "column",
        rowGap: 3,
      }}
      style={{
        paddingTop: 30,
      }}
    >
      <Typography
        sx={{ fontSize: { xs: 25, md: 35 } }}
        style={{
          fontFamily: "Montserrat",
          fontWeight: 700,
          color: "#282828",
        }}
      >
        Size Guide
      </Typography>
      <Section title="Men" sizes={menSizes} tableData={menChart} />

      <Section title="Dental" sizes={menSizes} tableData={dentalChart} />
      <Section title="Women" sizes={womenSizes} tableData={womenChart} />
    </Box>
  );
};
export default SizeChart;
