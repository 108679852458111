import axios from "axios";
// export const API_URL = "http://localhost:8000";
export const API_URL = "https://skrbs-backend-4721b32abe88.herokuapp.com";
export const IMAGE_URL = "https://res.cloudinary.com/duixtdmcz/";
export const api = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
  },
});
