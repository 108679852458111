import { Box, Button, Grid, Typography } from "@mui/material";
import FormField from "../../../components/FormField/FormField";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { API_URL } from "../../../api";
import axios from "axios";

const Profile = () => {
  const [resetPassword, setResetPassword] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const jsonString = localStorage.getItem("currentAdmin");
  const [currentAdmin, setCurrentAdmin] = useState(JSON.parse(jsonString));
  const [loading, setLoading] = useState();
  const [err, setError] = useState("");

  const initialValues = useMemo(
    () => ({
      email: currentAdmin.email,
      first_name: currentAdmin.first_name,
      last_name: currentAdmin.last_name,
    }),
    [currentAdmin]
  );
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          axios
            .patch(`${API_URL}/admins/${currentAdmin.id}`, values)
            .then((response) => {
              setLoading(false);
              const jsonString = JSON.stringify(response.data);
              localStorage.setItem("currentAdmin", jsonString);
              setCurrentAdmin(jsonString);
              setEdit(false);
              setResetPassword(false);
            })
            .catch((e) => {
              setLoading(false);
              setError("Invalid email or password");
            });
        }}
      >
        {({ values }) => (
          <Form>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                marginBottom: 50,
              }}
            >
              Personal Information{" "}
              <EditIcon sx={{ fontSize: 24 }} onClick={() => setEdit(true)} />
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`email`}
                  label="Email"
                  variant="outlined"
                  fullwidth
                  //   customizedStyling={customTextFieldStyling}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="input"
                  name={`first_name`}
                  label="First name"
                  variant="outlined"
                  fullwidth
                  //   customizedStyling={customTextFieldStyling}
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="input"
                  name={`last_name`}
                  label="Last Name"
                  variant="outlined"
                  fullwidth
                  //   customizedStyling={customTextFieldStyling}
                  disabled={!isEdit}
                />
              </Grid>
            </Grid>

            <Button
              onClick={() => setResetPassword(true)}
              variant="outlined"
              style={{
                color: "black",
                fontFamily: "Montserrat",
                borderColor: "black",
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              Reset password
            </Button>

            {resetPassword && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="input"
                    name={`password`}
                    label="Password"
                    variant="outlined"
                    fullwidth
                    //   customizedStyling={customTextFieldStyling}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="input"
                    name={`confirm_password`}
                    label="Confirm password"
                    variant="outlined"
                    fullwidth
                    //   customizedStyling={customTextFieldStyling}
                    required
                  />
                </Grid>
              </Grid>
            )}
            {(isEdit || resetPassword) && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "black",
                  fontFamily: "Montserrat",
                  borderColor: "black",
                  marginTop: 50,
                  marginBottom: 50,
                  marginLeft: 10,
                }}
              >
                Save
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default Profile;
