import { Grid, Typography } from "@mui/material";

const GridRecord = ({
  primaryText,
  secondaryText,
  primaryFontWeight,
  secondaryFontWeight,
  marginBottom = 20,
}) => {
  return (
    <Grid container style={{ marginBottom: marginBottom }}>
      <Grid item xs={8} md={8}>
        <Typography
          sx={{ fontSize: { xs: 15, md: 20 } }}
          style={{
            fontFamily: "Montserrat",
            fontWeight: primaryFontWeight,
            color: "#282828",
          }}
        >
          {primaryText}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography
          sx={{ fontSize: { xs: 15, md: 20 } }}
          style={{
            fontFamily: "Montserrat",
            fontWeight: secondaryFontWeight,
            color: marginBottom === 0 ? "black" : "rgba(40, 40, 40, 0.7)",
            overflowWrap: "break-word",
            maxWidth: "100%",
          }}
        >
          {secondaryText}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default GridRecord;
