import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { API_URL } from "../../../../api";
import AddressRecord from "./components/AddressRecord/AddressRecord";
import axios from "axios";

const Addresses = () => {
  const jsonString = localStorage.getItem("currentUser");
  const currentUser = jsonString && JSON.parse(jsonString);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addAddress, setAddAddress] = useState(false);

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/users/${currentUser?.id}/address`)
      .then((response) => {
        setLoading(false);
        setAddresses(response.data.addresses);
      })
      .catch(setLoading(false));
  };

  useEffect(() => {
    refetch();
  }, []);
  return loading ? (
    <Box
      style={{
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress
        style={{
          color: "black",
        }}
      />
    </Box>
  ) : (
    <Box
      sx={{
        ml: { md: 20 },
        mr: { md: 20 },
        display: "flex",
        flexDirection: "column",
        rowGap: 5,
      }}
    >
      <Typography
        style={{
          color: "black",
          cursor: "pointer",
          fontFamily: "Montserrat",
          fontWeight: 700,
        }}
        onClick={() => setAddAddress(true)}
      >
        Add new address +
      </Typography>
      {addresses.map((address, index) => (
        <AddressRecord address={address} index={index} refetch={refetch} />
      ))}
      <Dialog onClose={() => setAddAddress(false)} open={addAddress}>
        <AddressRecord
          refetch={refetch}
          setAddAddress={setAddAddress}
          isAdd={true}
        />
      </Dialog>
    </Box>
  );
};
export default Addresses;
