import React, { useCallback, useMemo, useState } from "react";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Logo from "../../../assets/images/skrbsLogo.png";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../api";
import { CircularProgress, TextField } from "@mui/material";

const theme = createTheme();
const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};
export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const initialValues = useMemo(
    () => ({
      email: "",
    }),
    []
  );

  const handleSendResetPassword = (event) => {
    event.preventDefault();
    setError("");
    const data = new FormData(event.currentTarget);
    setLoading(true);
    axios
      .post(`${API_URL}/admins/forget_password`, data)
      .then((response) => {
        setLoading(false);
        navigate("/admin");
      })
      .catch((err) => {
        setError("Failed to send reset link!");
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            alt="logo"
            style={{
              width: "60%",
            }}
          />

          <Box
            component="form"
            onSubmit={handleSendResetPassword}
            noValidate
            sx={{ mt: 3 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              sx={customTextFieldStyling}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ backgroundColor: "black", color: "white" }}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                "Reset"
              )}
            </Button>
            {error && (
              <Typography
                style={{ marginTop: 3, marginBottom: 3, color: "red" }}
              >
                Something went wrong
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
