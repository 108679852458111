import { CircularProgress, Container, Grid, Paper } from "@material-ui/core";
import TotalOrders from "./TotalOrders/TotalOrders";
import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { API_URL } from "../../../api";
import axios from "axios";
import { Box } from "@mui/material";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/orders/analytics`)
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch();
  }, []);

  const cashVsVisa = {
    labels: ["Cash", "Visa"],
    datasets: [
      {
        label: "orders",
        data: [data?.cash_orders_count, data?.visa_orders_count],
        backgroundColor: ["#53a653", "#1434CB"],
        borderColor: ["#53a653", "#1434CB"],
        borderWidth: 1,
      },
    ],
  };
  const orderStatusData = {
    labels: ["Pending", "Delivered", "Refunded"],
    datasets: [
      {
        label: "orders",
        data: [
          data?.pending_orders_count,
          data?.delivered_orders_count,
          data?.refund_orders_count,
        ],
        backgroundColor: ["#FFBF00", "#53a653", "#ff4500"],
        borderColor: ["#FFBF00", "#53a653", "#ff4500"],
        borderWidth: 1,
      },
    ],
  };
  return loading ? (
    <Box
      style={{
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress
        style={{
          color: "black",
        }}
      />
    </Box>
  ) : (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
            <TotalOrders total_count={data?.total_orders_count} text="Orders" />
          </Paper>
        </Grid>{" "}
        <Grid item xs={6} md={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
            <TotalOrders
              total_count={data?.total_products_count}
              text="Products"
            />
          </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
            <TotalOrders total_count={data?.total_employees} text="Employees" />
          </Paper>
        </Grid>{" "}
        <Grid item xs={6} md={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
            <TotalOrders
              total_count={data?.active_promocodes}
              text="Active promocodes"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Pie data={cashVsVisa} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Pie data={orderStatusData} />
        </Grid>
      </Grid>
    </Container>
  );
};
export default Dashboard;
