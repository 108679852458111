import { Button, Grid, Typography } from "@mui/material";

import { useNavigate } from "react-router";

import shopMen from "../../../../../../assets/images/shopMen.jpg";
import shopWomen from "../../../../../../assets/images/shopWomen.jpg";
import { useState } from "react";

const NewCollectionSection = () => {
  const navigate = useNavigate();
  const [onMenHover, setOnMenHover] = useState(false);
  const [onWomenHover, setOnWomenenHover] = useState(false);

  return (
    <Grid container spacing={3} style={{ marginTop: 60 }}>
      <Grid
        item
        md={4}
        style={{
          width: "100%",
          display: "flex",
          cursor: "pointer",
          position: "relative",
          width: "100%",
          height: "auto",
        }}
        onClick={() => navigate("/products?category=men")}
        onMouseEnter={() => setOnMenHover(true)}
        onMouseLeave={() => setOnMenHover(false)}
      >
        <img
          alt=""
          src={shopMen}
          style={{
            width: "100%",
            height: "auto",
            backgroundColor: "#ECECEC",
          }}
        />{" "}
        {onMenHover && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              border: "2px solid white",
              padding: "10px",
              background: "rgba(0, 0, 0, 0.5)",
              color: "white",
            }}
            onClick={() => navigate("/products?category=men")}
          >
            SHOP MEN
          </div>
        )}
      </Grid>

      <Grid
        item
        md={4}
        style={{
          display: "flex",
          cursor: "pointer",
          position: "relative",
          width: "100%",
          height: "auto",
        }}
        onClick={() => navigate("/products?category=women")}
        onMouseEnter={() => setOnWomenenHover(true)}
        onMouseLeave={() => setOnWomenenHover(false)}
      >
        <img
          alt=""
          src={shopWomen}
          style={{
            maxWidth: "100%",
            height: "auto",
            backgroundColor: "#ECECEC",
          }}
        />
        {onWomenHover && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              border: "2px solid white",
              padding: "10px",
              background: "rgba(0, 0, 0, 0.5)",
              color: "white",
            }}
            onClick={() => navigate("/products?category=men")}
          >
            SHOP WOMEN
          </div>
        )}
      </Grid>
      <Grid item md={3} sx={{ ml: { md: 3, lg: 9 } }}>
        <Typography
          sx={{
            fontSize: { md: 30, lg: 35 },
            mt: {
              md: 10,
              lg: 25,
            },
          }}
          style={{
            fontFamily: "Montserrat",
            fontWeight: 300,
            color: "#282828",
          }}
        >
          NEW
        </Typography>
        <Typography
          sx={{ fontSize: { md: 30, lg: 35 } }}
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: "#282828",
          }}
        >
          COLLECTION
        </Typography>
        <Typography
          sx={{ fontSize: { md: 10, lg: 15 } }}
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: "#282828",
          }}
        >
          Find out which uniforms are best for you
        </Typography>
        <Button
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = "#4B4B4B";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = "#282828";
          }}
          onClick={() => navigate("/products")}
          sx={{ fontSize: { md: 10, lg: 20 } }}
          style={{
            background: "#282828",
            borderRadius: 4,
            fontFamily: "Montserrat",
            fontWeight: 400,
            width: 200,
            color: "#FFFFFF",
            marginTop: 20,
          }}
        >
          Explore
        </Button>
      </Grid>
    </Grid>
  );
};
export default NewCollectionSection;
