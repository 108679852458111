import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { API_URL } from "../../../../../api";
import axios from "axios";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};
const AddPromocode = ({ setOpenAddPromocode, refetch }) => {
  const [loading, setLoading] = useState();
  const [err, setError] = useState("");

  const handleSubmit = (event) => {
    setError("");
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const values = {
      name: data.get("name"),
      percentage: data.get("percentage"),
      expiray_date: data.get("expiray_date"),
    };
    axios
      .post(`${API_URL}/promocode/`, values)
      .then((response) => {
        setLoading(false);
        refetch();
        setOpenAddPromocode(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 50,
        width: "100%",
      }}
    >
      <Typography
        sx={{ fontSize: { xs: 20, md: 25 } }}
        style={{
          fontFamily: "Montserrat",
          fontWeight: 700,
          color: "#282828",
          textAlign: "center",
        }}
      >
        Add new promocode
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Promocode name"
          name="name"
          autoComplete="name"
          autoFocus
          sx={customTextFieldStyling}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="percentage"
          label="Percentage value"
          id="percentage"
          autoComplete="percentage"
          sx={customTextFieldStyling}
        />
        <span>Expiray date</span>
        <TextField
          margin="normal"
          required
          fullWidth
          name="expiray_date"
          type="date"
          id="expiray_date"
          autoComplete="expiray_date"
          sx={customTextFieldStyling}
        />

        <Button
          type="submit"
          style={{ background: "black", color: "white", marginTop: 30 }}
          fullWidth
        >
          {loading ? <CircularProgress style={{ color: "white" }} /> : "Save"}
        </Button>
        {err && <Typography style={{ color: "red" }}>{err}</Typography>}
      </Box>
    </Box>
  );
};
export default AddPromocode;
