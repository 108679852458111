import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import skrbsLogo from "../../../../assets/images/skrbsLogo.png";

import Stepper from "./Components/Stepper";

const LeftContainer = ({
  setShippingFees,
  setOrderData,
  setIsVisaPay,
  setShippingEstimate,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          paddingLeft: { xs: "14px", md: "130px" },
          paddingTop: { xs: "21px", md: "60px" },
        }}
      >
        <img
          onClick={() => navigate("/")}
          src={skrbsLogo}
          alt="logo"
          style={{
            width: "77px",
            height: "44px",
            cursor: "pointer",
          }}
        />
      </Box>
      <Stepper
        setShippingFees={setShippingFees}
        setOrderData={setOrderData}
        setIsVisaPay={setIsVisaPay}
        setShippingEstimate={setShippingEstimate}
      />
    </>
  );
};
export default LeftContainer;
