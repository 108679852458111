import { Box, Divider, Typography } from "@mui/material";
import GridRecord from "./Components/GridRecord/GridRecord";
import ProductRecord from "./Components/ProductRecord/ProductRecord";

const OrderSummary = ({ orderDetails: details }) => {
  const orderDetails =
    details || JSON.parse(localStorage.getItem("confirmedOrder"));

  return (
    <Box
      sx={{ paddingLeft: { xs: 3, md: 10 } }}
      style={{
        background: "#FFFFFF",
        paddingTop: 50,
        paddingBottom: 10,
        width: "85%",
        marginLeft: 15,
      }}
    >
      <Typography
        sx={{ fontSize: { xs: 20, md: 25 } }}
        style={{
          fontFamily: "Montserrat",
          fontWeight: 700,
          color: "#282828",
          textAlign: "center",
        }}
      >
        ORDER SUMMARY
      </Typography>
      <Divider style={{ margin: "30px 40px 30px 0px" }} />
      <GridRecord
        primaryText="ORDER NUMBER"
        secondaryText={`#${orderDetails?.order_number}`}
        primaryFontWeight={500}
        secondaryFontWeight={400}
      />
      <GridRecord
        primaryText="Email"
        secondaryText={orderDetails?.user_id?.email}
        primaryFontWeight={500}
        secondaryFontWeight={400}
      />
      <GridRecord
        primaryText="Profession"
        secondaryText={orderDetails?.user_id?.profession}
        primaryFontWeight={500}
        secondaryFontWeight={400}
      />
      <GridRecord
        primaryText="Name"
        secondaryText={
          orderDetails?.user_id?.first_name +
          " " +
          orderDetails?.user_id?.last_name
        }
        primaryFontWeight={500}
        secondaryFontWeight={400}
      />
      <GridRecord
        primaryText="SHIPPING DETAILS"
        secondaryText={
          orderDetails?.address_id &&
          orderDetails.address_id.address_line1 +
            "," +
            orderDetails.address_id?.city +
            "," +
            orderDetails.address_id?.postal_code +
            "," +
            orderDetails.address_id?.country
        }
        primaryFontWeight={500}
        secondaryFontWeight={400}
      />
      <GridRecord
        primaryText="PHONE NUMBER"
        secondaryText={
          orderDetails?.address_id && orderDetails?.address_id?.phone_number
        }
        primaryFontWeight={500}
        secondaryFontWeight={400}
      />
      <Divider style={{ margin: "30px 40px 30px 0px" }} />
      {orderDetails.items.map((product) => {
        return <ProductRecord product={product} />;
      })}
      <Divider style={{ margin: "30px 40px 30px 0px" }} />
      <GridRecord
        primaryText="Subtotal"
        secondaryText={
          "LE" +
          (parseFloat(orderDetails?.totalPrice) -
            parseFloat(orderDetails?.shippingFees))
        }
        primaryFontWeight={500}
        secondaryFontWeight={500}
        marginBottom={5}
      />
      <GridRecord
        primaryText="Shipping"
        secondaryText={"LE " + orderDetails?.shippingFees}
        primaryFontWeight={500}
        secondaryFontWeight={500}
        marginBottom={5}
      />{" "}
      {orderDetails.promocode && (
        <GridRecord
          primaryText="Promo code"
          secondaryText={orderDetails?.promocode.name}
          primaryFontWeight={500}
          secondaryFontWeight={500}
          marginBottom={5}
        />
      )}
      <Divider style={{ margin: "30px 40px 30px 0px" }} />
      <GridRecord
        primaryText="Total"
        secondaryText={"LE " + orderDetails?.totalPrice}
        primaryFontWeight={700}
        secondaryFontWeight={700}
        marginBottom={0}
      />
      <Divider style={{ margin: "30px 40px 30px 0px" }} />
      <GridRecord
        primaryText="Payment Method:"
        secondaryText={orderDetails?.paymentMethod}
        primaryFontWeight={600}
        secondaryFontWeight={500}
        marginBottom={0}
      />
    </Box>
  );
};
export default OrderSummary;
