import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NewCollectionMob from "../../../../../../assets/images/NewCollectionMob.jpg";
import shopMen from "../../../../../../assets/images/shopMen.jpg";
import shopWomen from "../../../../../../assets/images/shopWomen.jpg";

const NewCollection = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
          style={{
            width: "100%",
            height: "auto",
          }}
          src={shopMen}
          alt="Shop Men"
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            border: "2px solid white",
            padding: "10px",
            background: "rgba(0, 0, 0, 0.5)",
            color: "white",
          }}
          onClick={() => navigate("/products?category=men")}
        >
          SHOP MEN
        </div>
      </Box>
      <Box style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
          style={{
            width: "100%",
            height: "auto",
          }}
          src={shopWomen}
          alt="Shop Women"
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            border: "2px solid white",
            padding: "10px",
            background: "rgba(0, 0, 0, 0.5)",
            color: "white",
          }}
          onClick={() => navigate("/products/?category=women")}
        >
          SHOP WOMEN
        </div>
      </Box>
      <Box style={{ position: "relative", width: "100%", height: "auto" }}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: 19,
            margin: 20,
          }}
        >
          WHERE COMFORT MEETS STYLE
        </Typography>
        <img
          style={{
            width: "100%",
            height: "auto",
          }}
          src={NewCollectionMob}
          alt="Shop Women"
        />
      </Box>
    </>
  );
};
export default NewCollection;
