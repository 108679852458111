import { Select } from "@material-ui/core";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { API_URL } from "../../../../../api";
import GridRecord from "../../../../../components/OrderSummary/Components/GridRecord/GridRecord";
import axios from "axios";

const statusOptions = [
  { value: "pending", label: "Pending" },
  { value: "delivered", label: "Delivered" },
  { value: "refund", label: "Refunded" },
];
const UpdateStatus = ({ orderDetails, setopenUpdateStatus, refetch }) => {
  const [status, setStatus] = useState(orderDetails.status);
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSave = () => {
    setLoading(true);
    axios
      .patch(`${API_URL}/orders/${orderDetails?.id}`, { status: status })
      .then((response) => {
        setLoading(false);
        setopenUpdateStatus(false);
        refetch();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 50,
        width: "100%",
      }}
    >
      <Typography
        sx={{ fontSize: { xs: 20, md: 25 } }}
        style={{
          fontFamily: "Montserrat",
          fontWeight: 700,
          color: "#282828",
          textAlign: "center",
        }}
      >
        Update Status
      </Typography>
      <Divider style={{ margin: "30px 0px 30px 0px" }} />
      <GridRecord
        primaryText="ORDER NUMBER"
        secondaryText={`#${orderDetails.order_number}`}
        primaryFontWeight={500}
        secondaryFontWeight={400}
      />
      <GridRecord
        primaryText="Current status"
        secondaryText={orderDetails.status}
        primaryFontWeight={500}
        secondaryFontWeight={400}
      />
      <Select
        value={status}
        label="Status"
        onChange={handleChange}
        fullWidth
        variant="outlined"
      >
        {statusOptions.map((option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
      <Button
        style={{ background: "black", color: "white", marginTop: 30 }}
        fullWidth
        onClick={() => handleSave()}
      >
        {loading ? <CircularProgress style={{ color: "white" }} /> : "Save"}
      </Button>
    </Box>
  );
};
export default UpdateStatus;
