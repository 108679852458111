import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Mailto from "../../../components/Mailto/Mailto";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import AddEmployee from "./AddEmployee/AddEmployee";
import { API_URL } from "../../../api";
import axios from "axios";
import { useNavigate } from "react-router";
import ConfirmDelete from "../../../components/ConfirmDelete";
import DeleteIcon from "@mui/icons-material/Delete";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  width: { xs: "100%", md: "60%" },
};
const Employees = () => {
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [currentRow, setCurrentRow] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const allUserEmails = () => {
    return rows?.map((row) => {
      return row.email;
    });
  };

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/admins/`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
        setFilteredData(response.data);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentAdmin");
    !isLoggedIn ? navigate("/admin") : refetch();
  }, []);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = rows.filter(
      (item) =>
        item?.first_name?.toLowerCase().includes(searchTerm) ||
        item?.last_name?.toLowerCase().includes(searchTerm) ||
        item?.email?.toLowerCase().includes(searchTerm)
    );
    setRows(filtered);
  };
  const handleDeleteEmployee = () =>
    axios
      .delete(`${API_URL}/admins/${currentRow.id}`)
      .then(() => {
        setOpenConfirmDelete(false);
        refetch();
      })
      .catch((err) => {});
  return loading ? (
    <Box
      style={{
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress
        style={{
          color: "black",
        }}
      />
    </Box>
  ) : (
    <TableContainer>
      <Box
        sx={{
          display: "flex",

          flexDirection: "row",
          columnGap: 3,
        }}
      >
        <TextField
          placeholder="Search by name or email"
          value={searchTerm}
          onChange={handleSearch}
          sx={customTextFieldStyling}
        />
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
          onClick={() => setOpenAddEmployee(true)}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
        >
          {" "}
          <Mailto emails={allUserEmails()?.join()}>Email all</Mailto>
        </Button>
      </Box>

      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold", fontFamily: "Montserrat" }}>
              Name
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Email
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Can manage dashboard
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Can manage users
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Can manage orders
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Can manage products
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Can manage featured products
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Can manage sale
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Can manage employees
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Can manage shipping fees
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Can manage promocodes
            </TableCell>{" "}
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Can manage categories
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
              >
                {row.first_name + " " + row.last_name}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="left"
              >
                {row.email}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="center"
              >
                {row?.permissions?.some(
                  (permission) =>
                    permission.permission.description === "can_manage_dashboard"
                ) && <CheckIcon color="black" />}
              </TableCell>{" "}
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="center"
              >
                {row?.permissions?.some(
                  (permission) =>
                    permission.permission.description === "can_manage_users"
                ) && <CheckIcon color="black" />}
              </TableCell>{" "}
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="center"
              >
                {row?.permissions?.some(
                  (permission) =>
                    permission.permission.description === "can_manage_orders"
                ) && <CheckIcon color="black" />}
              </TableCell>{" "}
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="center"
              >
                {row?.permissions?.some(
                  (permission) =>
                    permission.permission.description === "can_manage_products"
                ) && <CheckIcon color="black" />}
              </TableCell>{" "}
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="center"
              >
                {row?.permissions?.some(
                  (permission) =>
                    permission.permission.description ===
                    "can_manage_featured_products"
                ) && <CheckIcon color="black" />}
              </TableCell>{" "}
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="center"
              >
                {row?.permissions?.some(
                  (permission) =>
                    permission.permission.description === "can_manage_sale"
                ) && <CheckIcon color="black" />}
              </TableCell>{" "}
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="center"
              >
                {row?.permissions?.some(
                  (permission) =>
                    permission.permission.description === "can_manage_employees"
                ) && <CheckIcon color="black" />}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="center"
              >
                {row?.permissions?.some(
                  (permission) =>
                    permission.permission.description ===
                    "can_manage_shipping_fees"
                ) && <CheckIcon color="black" />}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="center"
              >
                {row?.permissions?.some(
                  (permission) =>
                    permission.permission.description ===
                    "can_manage_promocodes"
                ) && <CheckIcon color="black" />}
              </TableCell>{" "}
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="center"
              >
                {row?.permissions?.some(
                  (permission) =>
                    permission.permission.description ===
                    "can_manage_categories"
                ) && <CheckIcon color="black" />}
              </TableCell>{" "}
              <TableCell>
                <Button
                  onClick={() => {
                    setOpenConfirmDelete(true);
                    setCurrentRow(row);
                  }}
                >
                  <DeleteIcon style={{ color: "red" }} />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog onClose={() => setOpenAddEmployee(false)} open={openAddEmployee}>
        <AddEmployee
          setOpenAddEmployee={setOpenAddEmployee}
          refetch={refetch}
        />
      </Dialog>
      <Dialog
        onClose={() => setOpenConfirmDelete(false)}
        open={openConfirmDelete}
      >
        <ConfirmDelete
          onClose={() => setOpenConfirmDelete(false)}
          text={
            currentRow.first_name +
            " " +
            currentRow.last_name +
            " admin account"
          }
          onDelete={(e) => handleDeleteEmployee(e)}
        />
      </Dialog>
    </TableContainer>
  );
};
export default Employees;
