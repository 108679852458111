import { Box, Button, Grid, Typography } from "@mui/material";
import OrderSummary from "../../components/OrderSummary/OrderSummary";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import confirmOrder from "../../assets/images/confirmOrder.png";
import { useNavigate } from "react-router";

const ConfirmedOrder = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{ height: { xs: "100vh", md: "auto" },marginBottom:5 }}
      style={{ backgroundColor: "whiteSmoke" }}
    >
      <Button
        style={{ color: " #282828", marginBottom: 10 }}
        sx={{
          marginLeft: { xs: 1, md: 7 },
          marginTop: { xs: 1, md: 7 },
          fontSize: { xs: 10, md: 17 },
        }}
        onClick={() => navigate("/")}
      >
        <ArrowBackIosIcon style={{ fontSize: 17 }} />
        Back to homepage
      </Button>
      <Grid container>
        <Grid item xs={0} md={6}>
          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            style={{
              width: "100%",
            }}
          >
            <img
              alt=""
              src={confirmOrder}
              style={{
                maxWidth: "100%",
                height: "auto",
                marginLeft: "10%",
                marginTop: "8%",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontSize: { xs: 20, md: 27 },
            }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: "#282828",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            THANK YOU FOR YOUR ORDER!
          </Typography>
          <OrderSummary />
        </Grid>
      </Grid>
    </Box>
  );
};
export default ConfirmedOrder;
