import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 1,
}));

const SizeChartTable = ({ sizes, data }) => {
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: 400, overflowX: "auto" }}
    >
      <Table>
        <TableHead style={{ backgroundColor: "#D3D3D3" }}>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: "#D3D3D3",
                fontFamily: "Montserrat",
                fontWeight: 700,
                color: "#282828",
              }}
            >
              Size
            </StyledTableCell>
            {sizes?.map((size, index) => (
              <TableCell
                key={index}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  color: "#282828",
                }}
              >
                {size}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow key={index}>
              <StyledTableCell
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  color: "#282828",
                }}
              >
                {row.title}
              </StyledTableCell>
              <TableCell
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  color: "#282828",
                }}
              >
                {row.size1}
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  color: "#282828",
                }}
              >
                {row.size2}
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  color: "#282828",
                }}
              >
                {row.size3}
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  color: "#282828",
                }}
              >
                {row.size4}
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  color: "#282828",
                }}
              >
                {row.size5}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SizeChartTable;
