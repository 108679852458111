import { Button, CircularProgress, Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useCallback, useState } from "react";
import { API_URL } from "../../../../../../api";
import axios from "axios";
import { Typography } from "@mui/material";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};
const PromoCodeSection = ({ validPromocode, setValidPromocode }) => {
  const [loading, setLoading] = useState();
  const [err, setError] = useState("");
  const [promocode, setPromocode] = useState("");

  const handleApplyClick = useCallback((promocode) => {
    setLoading(true);
    setError("");
    setValidPromocode("");
    axios
      .get(`${API_URL}/promocode/name/${promocode}`)
      .then((response) => {
        setLoading(false);
        if (!response.data.is_expired) {
          setValidPromocode(response.data);
        } else {
          setError("Promocode expired !");
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Invalid promocode !");
      });
  }, []);
  return (
    <Grid container>
      <Grid item xs={7} md={7} lg={8}>
        <TextField
          label="Promocode"
          variant="outlined"
          fullWidth
          sx={customTextFieldStyling}
          onChange={(event) => setPromocode(event.target.value)}
        />
      </Grid>
      <Grid item xs={3} md={3} lg={3}>
        <Button
          onClick={() => handleApplyClick(promocode)}
          style={{
            backgroundColor: "black",
            fontFamily: "'Montserrat",
            color: "white",
            padding: 15,
            marginLeft: 15,
          }}
        >
          {loading ? <CircularProgress style={{ color: "white" }} /> : "Apply"}
        </Button>
      </Grid>{" "}
      {err && (
        <Typography style={{ color: "black", marginTop: 20 }}>{err}</Typography>
      )}
      {validPromocode && (
        <Typography style={{ color: "black", marginTop: 20 }}>
          {validPromocode.name} promocode applied successfully !
        </Typography>
      )}
    </Grid>
  );
};
export default PromoCodeSection;
