import { Box, Button, CircularProgress, Dialog } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import ConfirmDelete from "../../../components/ConfirmDelete";
import { API_URL } from "../../../api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddPromocode from "./Components/AddPromocode/AddPromocode";

const Promocodes = () => {
  const [openAddPromocode, setOpenAddPromocode] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/promocode/`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentAdmin");

    !isLoggedIn ? navigate("/admin") : refetch();
  }, []);

  const handleOnDelete = (event) => {
    axios
      .delete(`${API_URL}/promocode/${currentRow.id}`)
      .then((response) => {
        setLoading(false);
        refetch();
        setOpenConfirmDelete(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  return loading ? (
    <Box
      style={{
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress
        style={{
          color: "black",
        }}
      />
    </Box>
  ) : (
    <TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", md: "flex-end" },
        }}
      >
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
          onClick={() => {
            setOpenAddPromocode(true);
          }}
        >
          Add new promocode
        </Button>
      </Box>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold", fontFamily: "Montserrat" }}>
              Promocode
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Percentage
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Expiray date
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
              >
                {row.name}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="left"
              >
                {row.percentage}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="left"
              >
                {row.expiray_date}
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
                align="left"
              >
                <DeleteIcon
                  style={{
                    cursor: "pointer",
                    color: "red",
                  }}
                  onClick={() => {
                    setOpenConfirmDelete(true);
                    setCurrentRow(row);
                  }}
                />
              </TableCell>{" "}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        onClose={() => setOpenAddPromocode(false)}
        open={openAddPromocode}
      >
        <AddPromocode
          setOpenAddPromocode={setOpenAddPromocode}
          refetch={refetch}
        />
      </Dialog>

      <Dialog
        onClose={() => setOpenConfirmDelete(false)}
        open={openConfirmDelete}
      >
        <ConfirmDelete
          close={setOpenConfirmDelete}
          text={currentRow.name + " promocode"}
          onDelete={handleOnDelete}
        />
      </Dialog>
    </TableContainer>
  );
};
export default Promocodes;
