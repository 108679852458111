import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm/CheckoutForm";
import { API_URL } from "../../api";
import axios from "axios";
import { CircularProgress, Typography, Box } from "@mui/material";

const StripePayment = ({ price, email, order_values }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const amount = price * 100;
    axios
      .post(`${API_URL}/payments/secret/`, {
        amount: parseInt(amount),
        email: email,
      })
      .then((response) => {
        setClientSecret(response.data["client_secret"]);
      })
      .catch((err) => {
        setError(true);
      });
  }, [price]);
  const options = {
    clientSecret: clientSecret,
  };
  useEffect(() => {
    if (!stripePromise) {
      setStripePromise(
        loadStripe(
          "pk_test_51M3qpKHUNLlqYNhphZRHdezHROlJdkM9aMRJvcwBnK4xLp6YL5vVJG8Xp1EX6snuOpSbM7Xr08u7U2Z9waMlYEQM00C1KppXs4"
        )
      );
    }
  }, [stripePromise]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {clientSecret && stripePromise && !error ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm price={price} order_values={order_values} />
        </Elements>
      ) : (
        <CircularProgress style={{ color: "black" }} />
      )}
      {error && (
        <Typography style={{ color: "red", paddingLeft: 10 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default StripePayment;
