import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { API_URL } from "../../../../../api";
import axios from "axios";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};
const AddCity = ({ setOpenAddCity, refetch }) => {
  const [loading, setLoading] = useState();
  const [err, setError] = useState("");

  const handleSubmit = (event) => {
    setError("");
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const values = {
      city: data.get("city"),
      fees: data.get("fees"),
      estimate_shipping: data.get("estimate_shipping"),
    };
    axios
      .post(`${API_URL}/courierFees/`, values)
      .then((response) => {
        setLoading(false);
        refetch();
        setOpenAddCity(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 50,
        width: "100%",
      }}
    >
      <Typography
        sx={{ fontSize: { xs: 20, md: 25 } }}
        style={{
          fontFamily: "Montserrat",
          fontWeight: 700,
          color: "#282828",
          textAlign: "center",
        }}
      >
        Add new city
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="city"
          label="City"
          name="city"
          autoComplete="city"
          autoFocus
          sx={customTextFieldStyling}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="fees"
          label="Shipping Fees"
          type="fees"
          id="fees"
          autoComplete="fees"
          sx={customTextFieldStyling}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="estimate_shipping"
          label="Shipping esitmate"
          type="estimate_shipping"
          id="estimate_shipping"
          autoComplete="Estimate shipping"
          sx={customTextFieldStyling}
          helperText="In days. Ex: 2-4"
        />

        <Button
          type="submit"
          style={{ background: "black", color: "white", marginTop: 30 }}
          fullWidth
        >
          {loading ? <CircularProgress style={{ color: "white" }} /> : "Save"}
        </Button>
        {err && <Typography style={{ color: "red" }}>{err}</Typography>}
      </Box>
    </Box>
  );
};
export default AddCity;
