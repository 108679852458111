import { Box, Grid, Typography } from "@mui/material";

import { useState, useEffect } from "react";
import { API_URL, IMAGE_URL } from "../../../../../../api";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FeaturedItems = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/featured`)
      .then((response) => {
        setLoading(false);
        setItems(response.data);
      })
      .catch();
  }, []);
  return (
    items.length > 0 && (
      <>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 550,
            fontSize: 30,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          Featured Products
        </Typography>

        <Grid container spacing={3}>
          {items.map((item) => {
            return (
              <Grid
                item
                xs={3}
                onClick={() => navigate(`product-details/${item.id}`)}
              >
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    height: 400,
                    backgroundColor: "#ECECEC",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt=""
                    src={
                      item?.colors[0]?.images &&
                      IMAGE_URL + "/" + item?.colors[0]?.images[0]
                    }
                    style={{
                      maxWidth: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 15,
                    fontWeight: 600,
                    textAlign: "left",
                    marginTop: 15,
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    color: "#000000",
                  }}
                >
                  LE {item.price}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </>
    )
  );
};
export default FeaturedItems;
