import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { API_URL } from "../../../api";
import axios from "axios";
import { useState } from "react";
const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};
const SignupForm = () => {
  const [loading, setLoading] = useState();
  const [err, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const values = {
      email: data.get("email"),
      password: data.get("password"),
      first_name: data.get("first_name"),
      last_name: data.get("last_name"),
      is_guest: false,
    };
    axios
      .post(`${API_URL}/users/`, values)
      .then((response) => {
        setLoading(false);
        const jsonString = JSON.stringify(response.data);
        localStorage.setItem("currentUser", jsonString);
        navigate("/profile");
      })
      .catch((e) => {
        setLoading(false);
        if (
          e?.response?.data?.email &&
          e?.response?.data?.email[0] === "user with this email already exists."
        )
          setError("Email already exists.");
        else setError("Invalid email or password");
      });
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: { xs: 3, md: 10 },
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontSize: { xs: 20, md: 30 } }}
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: " #28282",
          }}
        >
          Create your SKRBS account{" "}
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstname"
            label="First name"
            name="first_name"
            autoFocus
            sx={customTextFieldStyling}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastname"
            label="Last name"
            name="last_name"
            sx={customTextFieldStyling}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            sx={customTextFieldStyling}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            sx={customTextFieldStyling}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "black", color: "white" }}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <CircularProgress style={{ color: "white" }} />
            ) : (
              " Sign up"
            )}
          </Button>
          {err && <Typography style={{ color: "red" }}>{err}</Typography>}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 300,
                fontSize: 15,
                color: "#4B4B4B",
              }}
            >
              By clicking the button above, you agree to our terms of Use and
              Privacy Policy.
            </Typography>
            <Typography
              sx={{ fontSize: { xs: 15, md: 20 }, mt: 3, mb: 3 }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                color: "#282828",
              }}
            >
              Have an account?
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 300,
                  color: "#282828",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/signin")}
              >
                Log in here{" "}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default SignupForm;
