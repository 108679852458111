import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import FormField from "../../../../../../components/FormField/FormField";
import EditIcon from "@mui/icons-material/Edit";
import { API_URL } from "../../../../../../api";
import axios from "axios";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};
const AddressRecord = ({
  address,
  index,
  refetch,
  isAdd = false,
  setAddAddress,
}) => {
  const jsonString = localStorage.getItem("currentUser");
  const currentUser = jsonString && JSON.parse(jsonString);
  const [loading, setLoading] = useState();
  const [isEdit, setEdit] = useState(false);
  const [govs, setGovs] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/courierFees/`)
      .then((response) => {
        setGovs(response.data);
      })
      .catch();
  }, []);

  const initialValues = useMemo(
    () => ({
      address_line1: address?.address_line1,
      postal_code: address?.postal_code,
      city: address?.city,
      phone_number: address?.phone_number,
      country: address?.country,
    }),
    [address]
  );
  return (
    <Box border="1px solid grey" borderRadius="4px" padding={3}>
      {!isAdd && (
        <Box style={{ display: "flex", flexDirection: "row", columnGap: 15 }}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              color: "#282828",
            }}
          >
            Address {index + 1}
          </Typography>
          <EditIcon onClick={() => setEdit(true)} />
        </Box>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          isAdd
            ? axios
                .post(`${API_URL}/users/${currentUser?.id}/address`, values)
                .then((response) => {
                  setLoading(false);
                  refetch();
                  setAddAddress(false);
                  axios
                    .get(`${API_URL}/users/${currentUser?.id}/address`, values)
                    .then((response) => {
                      const jsonString = JSON.stringify(response.data);
                      localStorage.setItem("currentUser", jsonString);
                    })
                    .catch((e) => {
                      setLoading(false);
                    });
                })
                .catch((e) => {
                  setLoading(false);
                })
            : axios
                .patch(
                  `${API_URL}/users/${currentUser?.id}/address/${address.id}`,
                  values
                )
                .then((response) => {
                  setLoading(false);
                  setEdit(false);
                  refetch();
                })
                .catch((e) => {
                  setLoading(false);
                });
        }}
      >
        {({ values, errors, dirty }) => (
          <Form>
            {" "}
            <Grid container style={{ marginTop: 30 }}>
              <Grid item xs={12} style={{ marginBottom: 17 }}>
                <FormField
                  type="select"
                  name={`country`}
                  label="Country/Region"
                  fullwidth
                  variant="outlined"
                  required
                  options={[{ label: "Egypt", value: "Egypt" }]}
                  disabled={!isEdit && !isAdd}
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12} style={{ marginBottom: 17 }}>
                <FormField
                  type="input"
                  name={`address_line1`}
                  label="Address"
                  variant="outlined"
                  fullwidth
                  disabled={!isEdit && !isAdd}
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} style={{ marginBottom: 17 }}>
                <FormField
                  type="select"
                  name={`city`}
                  label="City"
                  fullwidth
                  variant="outlined"
                  required
                  options={govs?.map((gov) => {
                    return { label: gov.city, value: gov.city };
                  })}
                  disabled={!isEdit && !isAdd}
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>

              <Grid item xs={12} md={6} style={{ marginBottom: 17 }}>
                <FormField
                  type="input"
                  name={`postal_code`}
                  label="Postal code"
                  variant="outlined"
                  fullwidth
                  disabled={!isEdit && !isAdd}
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormField
                  type="input"
                  name={`phone_number`}
                  label="Phone number"
                  variant="outlined"
                  fullwidth
                  disabled={!isEdit && !isAdd}
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
            </Grid>
            {(isEdit || isAdd) && (
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "black",
                  fontFamily: "Montserrat",
                  borderColor: "black",
                  marginTop: 50,
                  marginBottom: 50,
                  marginLeft: 10,
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  "Save"
                )}
              </Button>
            )}
          </Form>
        )}
      </Formik>{" "}
    </Box>
  );
};
export default AddressRecord;
