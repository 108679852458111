import { Typography } from "@mui/material";
import SizeChartTable from "../Table/Table";

const Section = ({ title, sizes, tableData }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 20 }}>
      <Typography
        sx={{ fontSize: { xs: 20, md: 25 } }}
        style={{
          fontFamily: "Montserrat",
          fontWeight: 700,
          color: "#282828",
        }}
      >
        {title}
      </Typography>
      <SizeChartTable sizes={sizes} data={tableData} />
    </div>
  );
};
export default Section;
