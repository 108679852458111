import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import skrbsLogo from "../../../assets/images/skrbsLogo.png";
import { API_URL } from "../../../api";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        SKRBS
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [loading, setLoading] = React.useState();
  const [err, setError] = React.useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    setError("");
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const values = {
      email: data.get("email"),
      password: data.get("password"),
    };
    axios
      .post(`${API_URL}/admins/login`, values)
      .then((response) => {
        setLoading(false);
        const jsonString = JSON.stringify(response.data);
        localStorage.setItem("currentAdmin", jsonString);
        navigate("profile");
      })
      .catch((e) => {
        setLoading(false);
        setError("Invalid email or password");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={skrbsLogo}
            alt="logo"
            style={{
              width: "77px",
              height: "44px",
            }}
          />

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              sx={customTextFieldStyling}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              sx={customTextFieldStyling}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ backgroundColor: "black", color: "white" }}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                " Sign In"
              )}
            </Button>
            {err && <Typography style={{ color: "red" }}>{err}</Typography>}

            {/* <Grid container>
              <Grid item xs>
                <Link
                  href="/send-reset-password"
                  variant="body2"
                  style={{ color: "black", textDecorationColor: "black" }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
