import Box from "@mui/material/Box";
import { useCallback, useMemo, useState } from "react";
import Information from "../../Components/Information";
import Payment from "../../Components/Payment";
import Shipping from "../../Components/Shipping";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import { Formik, Form } from "formik";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CircularProgress, Grid, Tooltip } from "@mui/material";
import { API_URL } from "../../../../../../api";
import axios from "axios";
import * as Yup from "yup";

const steps = ["Cart", "Information", "Payment"];

const useStyles = makeStyles(() => ({
  root: {
    "& .Mui-active .MuiStepIcon-root": { color: "#4AC1B7" },
    "& .Mui-completed .MuiStepIcon-root": { color: "#4AC1B7" },
  },
}));

const validationSchema = Yup.object()
  .shape({
    address_id: Yup.string().nullable(),

    user: Yup.object().shape({
      email: Yup.string().email("Invalid email format").required("Required!"),
      profession: Yup.string().required("Required!"),

      first_name: Yup.string().required("Required!"),
      last_name: Yup.string().required("Required!"),
      address: Yup.object()
        .shape({
          address_line1: Yup.string().required("Required!"),
          city: Yup.string().required("Required!"),
          phone_number: Yup.string()
            .matches(/^\d{11}$/, "Phone number must be 11 digits")
            .required("Required!"),
          country: Yup.string().required("Required!"),
        })
        .nullable(),
    }),
  })
  .test("address-or-id", "Required", function (value) {
    const { address_id, user } = value;
    const hasAddressId = !!address_id;
    const hasAddress = !!user?.address?.address_line1;

    return hasAddressId || hasAddress;
  });

const FormStepper = ({
  setShippingFees,
  setOrderData,
  setIsVisaPay,
  setShippingEstimate,
}) => {
  const c = useStyles();

  const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState();
  const [err, setError] = useState("");

  const navigate = useNavigate();
  const getStepContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return navigate("/cart");
        case 1:
          return (
            <Information
              setShippingFees={setShippingFees}
              setShippingEstimate={setShippingEstimate}
            />
          );
        case 2:
          return <Payment setActiveStep={setActiveStep} />;
        default:
          throw new Error("Unknown step");
      }
    },
    [navigate]
  );

  const handleNext = (values) => {
    if (activeStep + 1 === 3) {
      setLoading(true);
      const cartDetails = JSON.parse(localStorage.getItem("cart"));
      const promocode_id = JSON.parse(localStorage.getItem("promocode_id"));
      const shippingFees = JSON.parse(localStorage.getItem("shippingFees"));
      const totalPrice = JSON.parse(localStorage.getItem("totalPrice"));

      values.products = [];
      cartDetails?.map((item) => {
        values.products.push({
          product_id: item.id,
          color: item.color,
          size: item.size,
          quantity: item.quantity,
          customization: item.embriodery && {
            title: item.embriodery.title,
            text: item.embriodery.text,
            color: item.embriodery.color,
            font: item.embriodery.font,
            placement: item.embriodery.placement,
          },
        });
      });
      if (promocode_id) values.promocode = promocode_id;
      values.shippingFees = shippingFees;
      values.totalPrice = totalPrice;
      if (values.paymentMethod == "visa") {
        setOrderData(values);
        setIsVisaPay(true);
      } else
        axios
          .post(`${API_URL}/orders/`, values)
          .then((response) => {
            setLoading(false);
            localStorage.setItem("cart", []);
            localStorage.setItem(
              "confirmedOrder",
              JSON.stringify(response.data)
            );
            localStorage.setItem("promocode_id", null);
            localStorage.setItem("shippingFees", 0);
            localStorage.setItem("totalPrice", 0);

            return navigate("/confirmedOrder");
          })
          .catch((e) => {
            setLoading(false);
            setError("Failed to create order");
          });
    } else setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const initialValues = useMemo(
    () => ({
      email: "",
      address_id: "",
      user: { address: {} },
    }),
    []
  );
  return (
    <Box
      maxWidth="md"
      sx={{ mb: 4, mr: { xs: 3, md: 10 }, ml: { xs: 0, md: 10 } }}
    >
      <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label}
            className={c.root}
            onClick={() => setActiveStep(index)}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <>{loading && <CircularProgress style={{ color: "white" }} />}</>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            children={(Information, Shipping, Payment)}
            validationSchema={validationSchema}
          >
            {({ values, errors, isValid, dirty }) => (
              <>
                <Form>{getStepContent(activeStep, values, errors, dirty)}</Form>
                <Grid container style={{ marginTop: 17 }}>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => navigate("/cart")}
                      style={{ color: " #282828" }}
                      sx={{ marginLeft: { xs: 1, md: 7 } }}
                    >
                      <ArrowBackIosIcon style={{ fontSize: 15 }} />
                      Return to Cart
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {activeStep !== 1 && (
                        <Button
                          style={{ color: " #282828" }}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                      )}

                      <Tooltip
                        title={
                          !isValid || !dirty
                            ? "All required fields must be filled"
                            : ""
                        }
                        arrow
                        placement="top"
                        disableHoverListener={isValid && dirty}
                        interactive
                        enterTouchDelay={0}
                      >
                        <span>
                          <Button
                            style={{
                              backgroundColor:
                                !isValid || !dirty ? "grey" : "#282828",
                              padding: 10,
                              color: "white",
                            }}
                            variant="contained"
                            onClick={() => handleNext(values)}
                            disabled={!isValid || !dirty}
                          >
                            {activeStep !== steps.length - 1 ? (
                              "Next"
                            ) : loading ? (
                              <CircularProgress style={{ color: "white" }} />
                            ) : (
                              "Done"
                            )}
                          </Button>
                        </span>
                      </Tooltip>
                      {err && (
                        <Typography style={{ color: "red" }}>{err}</Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Formik>
        </>
      )}
    </Box>
  );
};
export default FormStepper;
