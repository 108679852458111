import {
  Box,
  Grid,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import FormField from "../../../../../../components/FormField/FormField";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { API_URL } from "../../../../../../api";
import axios from "axios";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};
const professionOptions = [
  { value: "Doctor", label: "Doctor" },
  { value: "Student", label: "Student" },
  { value: "Nurse", label: "Nurse" },
  { value: "Dentist", label: "Dentist" },
  { value: "Physician", label: "Physician" },
];
const Information = ({ setShippingFees, setShippingEstimate }) => {
  const navigate = useNavigate();
  const jsonString = localStorage.getItem("currentUser");
  const currentUser = jsonString && JSON.parse(jsonString);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const { values, setFieldValue } = useFormikContext();
  const [govs, setGovs] = useState([]);

  const refetch = () => {
    axios
      .get(`${API_URL}/courierFees/`)
      .then((response) => {
        setGovs(response.data);
      })
      .catch();
  };

  useEffect(() => {
    refetch();
  }, []);
  const handleAddressChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedAddress(selectedIndex);
    const address = currentUser?.addresses[selectedIndex];
    setFieldValue("user.address.address_line1", address.address_line1);
    setFieldValue("user.address.city", address.city);
    setFieldValue("user.address.postal_code", address.postal_code);
    setFieldValue("user.address.phone_number", address.phone_number);
    setFieldValue("user.address.country", address.country);
    setFieldValue("address_id", address.id);

    // set shipping fees

    const shipping_fees = govs.filter(
      (gov) => gov.city.toLowerCase() == address.city.toLowerCase()
    )[0];

    setShippingFees(shipping_fees?.fees);
    setShippingEstimate(shipping_fees?.estimate_shipping);
  };
  useEffect(() => {
    if (currentUser) {
      setFieldValue("user.email", currentUser?.email);
      setFieldValue("user.first_name", currentUser?.first_name);
      setFieldValue("user.last_name", currentUser?.last_name);
      setFieldValue("address_id", currentUser.addresses[0].id);
      setFieldValue("user.profession", currentUser?.profession);

      if (currentUser?.addresses) {
        setFieldValue(
          "user.address.address_line1",
          currentUser?.addresses[0].address_line1
        );
        setFieldValue("user.address.city", currentUser?.addresses[0].city);
        setFieldValue(
          "user.address.postal_code",
          currentUser?.addresses[0].postal_code
        );
        setFieldValue(
          "user.address.phone_number",
          currentUser?.addresses[0].phone_number
        );
        setFieldValue(
          "user.address.country",
          currentUser?.addresses[0].country
        );
      }

      // set shipping fees
      const city = currentUser?.addresses[0].city;
      const shipping_fees = govs.filter(
        (gov) => gov.city.toLowerCase() == city.toLowerCase()
      )[0];
      setShippingFees(shipping_fees?.fees);
      setShippingEstimate(shipping_fees?.estimate_shipping);
    }
  }, [govs]);

  const handleCityChange = (event) => {
    setFieldValue("user.address.city", event.target.value.city);
    setShippingFees(event.target.value.fees);
    setShippingEstimate(event.target.value.estimate_shipping);
  };
  return (
    <Box sx={{ marginLeft: { xs: 3, md: 7 } }}>
      <Grid container>
        <Grid item xs={3} md={6} lg={6}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 17,
              color: " #000000",
              marginBottom: 16,
            }}
          >
            Contact
          </Typography>
        </Grid>
        <Grid item xs={9} md={6} lg={6}>
          {!currentUser && (
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: 15,
                color: " #000000",
                fontWeight: 500,
              }}
            >
              Already have an account?
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => navigate("/signin?redirect=checkout")}
              >
                Log in
              </span>
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ marginBottom: 24 }}>
          {currentUser ? (
            <TextField
              sx={customTextFieldStyling}
              value={currentUser?.email}
              disabled
              fullWidth
            />
          ) : (
            <FormField
              type="input"
              name={`user.email`}
              label="Email"
              variant="outlined"
              fullwidth
              customizedStyling={customTextFieldStyling}
            />
          )}
        </Grid>{" "}
        {!currentUser && (
          <Grid item xs={12} md={12} lg={12} style={{ marginBottom: 17 }}>
            <FormField
              type="select"
              name={`user.profession`}
              label="Profession"
              variant="outlined"
              fullwidth
              customizedStyling={customTextFieldStyling}
              options={professionOptions}
            />
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 17,
              color: " #000000",
              marginBottom: 20,
            }}
          >
            Shipping address
          </Typography>
        </Grid>
        {!currentUser && (
          <>
            <Grid item xs={12} style={{ marginBottom: 17 }}>
              <FormField
                type="select"
                name={`user.address.country`}
                label="Country/Region"
                fullwidth
                variant="outlined"
                required
                options={[{ label: "Egypt", value: "Egypt" }]}
                customizedStyling={customTextFieldStyling}
              />
            </Grid>{" "}
          </>
        )}
      </Grid>
      {!currentUser ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6} style={{ marginBottom: 17 }}>
              <FormField
                type="input"
                name={`user.first_name`}
                label="First name"
                variant="outlined"
                fullwidth
                customizedStyling={customTextFieldStyling}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} style={{ marginBottom: 17 }}>
              <FormField
                type="input"
                name={`user.last_name`}
                label="Last name"
                variant="outlined"
                fullwidth
                customizedStyling={customTextFieldStyling}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ marginBottom: 17 }}>
              <FormField
                type="input"
                name={`user.address.address_line1`}
                label="Address"
                variant="outlined"
                fullwidth
                customizedStyling={customTextFieldStyling}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} style={{ marginBottom: 17 }}>
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: "black",
                    "& label.Mui-focused": {
                      color: "black",
                    },
                    "& label.MuiInputLabel-formControl": {
                      color: "black",
                    },
                  }}
                >
                  City
                </InputLabel>
                <Select
                  required
                  label="City"
                  variant="outlined"
                  onChange={handleCityChange}
                >
                  {govs?.map((option) => (
                    <MenuItem value={option}>{option.city}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ marginBottom: 17 }}>
              <FormField
                type="input"
                name={`user.address.postal_code`}
                label="Postal code"
                variant="outlined"
                fullwidth
                customizedStyling={customTextFieldStyling}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormField
                type="input"
                name={`user.address.phone_number`}
                label="Phone number"
                variant="outlined"
                fullwidth
                customizedStyling={customTextFieldStyling}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Box style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
          {currentUser?.addresses ? (
            <RadioGroup value={selectedAddress} onChange={handleAddressChange}>
              {currentUser?.addresses.map((address, index) => (
                <Box
                  key={index}
                  border="0.5px solid gray"
                  borderRadius="4px"
                  padding={3}
                  marginBottom={2}
                >
                  <FormControlLabel
                    value={index.toString()}
                    control={<Radio color="default" />}
                    label={
                      <div>
                        <Typography>
                          <span style={{ fontWeight: 700 }}>
                            Shipping details:{" "}
                          </span>
                          {address?.address_line1 +
                            ", " +
                            address?.city +
                            ", " +
                            address?.postal_code +
                            ", " +
                            address?.country}
                        </Typography>
                        <Typography>
                          <span style={{ fontWeight: 700 }}>
                            {" "}
                            Phone number:
                          </span>{" "}
                          {address?.phone_number}
                        </Typography>
                      </div>
                    }
                  />
                </Box>
              ))}
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 15,
                  color: " #000000",
                }}
              >
                * If you wish to checkout using a different address, kindly
                proceed to your
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => navigate("/profile")}
                >
                  {" "}
                  profile{" "}
                </span>
                and add a new one.
              </Typography>
            </RadioGroup>
          ) : (
            <Typography>
              You haven't saved any addresses yet. Please navigate to your
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => navigate("/profile")}
              >
                {" "}
                profile{" "}
              </span>
              and add a new address.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
export default Information;
