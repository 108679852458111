import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import AddorEditProduct from "../AddorEditProduct";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_URL, IMAGE_URL } from "../../../../../api";
import axios from "axios";
import ConfirmDelete from "../../../../../components/ConfirmDelete";

const ProductCard = ({ product, refetch }) => {
  const [openEditProduct, setopenEditProduct] = useState(false);
  const navigate = useNavigate();
  const [currentRow, setCurrentRow] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleDeleteProduct = () =>
    axios
      .delete(`${API_URL}/products/${currentRow.id}`)
      .then(() => {
        setOpenConfirmDelete(false);
        refetch();
      })
      .catch((err) => {});
  return (
    product && (
      <>
        <Box
          item
          style={{
            border: "1px solid #ECECEC",
            height: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            cursor: "pointer",
          }}
          onClick={() => navigate(`/collection/${product?.id}`)}
          sx={{ width: { xs: 280, sm: 350 } }}
        >
          <img
            src={
              product?.colors[0]?.images &&
              IMAGE_URL + "/" + product?.colors[0]?.images[0]
            }
            style={{
              width: "70%",
              maxHeight: "100%",
            }}
            alt={product.name}
          />
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Grid item xs={5.5} md={6}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 18,
                color: "#000000",
              }}
            >
              {product?.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2.5}
            md={3}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Button
              onClick={() => {
                setCurrentRow(product);
                setopenEditProduct(true);
              }}
              style={{ padding: 0, color: "black", borderColor: "black" }}
              variant="outlined"
            >
              Edit <EditIcon style={{ fontSize: 17 }} />
            </Button>

            <Button
              onClick={() => {
                setOpenConfirmDelete(true);
                setCurrentRow(product);
              }}
            >
              <DeleteIcon style={{ color: "red" }} />
            </Button>
          </Grid>
        </Grid>

        <Dialog
          onClose={() => setopenEditProduct(false)}
          open={openEditProduct}
        >
          <AddorEditProduct
            isEdit
            product={product}
            setopenEditProduct={setopenEditProduct}
            initialData={currentRow}
            refetch={refetch}
          />
        </Dialog>
        <Dialog
          onClose={() => setOpenConfirmDelete(false)}
          open={openConfirmDelete}
        >
          <ConfirmDelete
            onClose={() => setOpenConfirmDelete(false)}
            text={currentRow.name}
            onDelete={(e) => handleDeleteProduct(e)}
          />
        </Dialog>
      </>
    )
  );
};
export default ProductCard;
