import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { API_URL, IMAGE_URL } from "../../../../../../api";
import Carousel from "../../../../../../components/Carousel/Carousel";
import ImageContainer from "../../../../../../components/ImageContainer/ImageContainer";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FeaturedItems = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/featured`)
      .then((response) => {
        setLoading(false);
        setItems(response.data);
      })
      .catch();
  }, []);
  return (
    items.length > 0 && (
      <Box>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: 25,
            margin: 20,
          }}
        >
          Featured Products
        </Typography>
        <Box sx={{ display: { xs: "initial", md: "none" } }}>
          <Carousel
            slidesToShow={1}
            slidesToScroll={1}
            sliderContentData={items}
            infinite
            sliderContent={items.map((item, index) => (
              <div
                key={index}
                className="slick-slide"
                onClick={() => navigate(`product-details/${item.id}`)}
              >
                <ImageContainer
                  image={
                    item?.colors[0]?.images &&
                    IMAGE_URL + "/" + item?.colors[0]?.images[0]
                  }
                />
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 14,
                    fontWeight: 600,
                    textAlign: "center",
                    marginTop: 3,
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    textAlign: "center",

                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    color: "#000000",
                    marginLeft: 5,
                  }}
                >
                  LE {item.price}
                </Typography>
              </div>
            ))}
          />
        </Box>
      </Box>
    )
  );
};
export default FeaturedItems;
