import { Box, Divider, List, ListItem } from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import StyledListItem from "../StyledListItem/StyledListItem";
import { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import skrbsLogo from "../../../../assets/images/skrbsLogo.png";
import { useNavigate } from "react-router-dom";

const OnMobDrawer = ({ pages, toggleDrawer, onDrawerClick }) => {
  const [openSupport, setOpenSupport] = useState(true);
  const [openAccount, setOpenAccount] = useState(false);
  const jsonString = localStorage.getItem("currentUser");
  const currentUser = jsonString && JSON.parse(jsonString);
  const navigate = useNavigate();
  return (
    <List style={{ width: "100vw", height: "100vh" }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <ListItem>
          {" "}
          <img
            onClick={() => navigate("/")}
            src={skrbsLogo}
            alt="logo"
            style={{
              width: "77px",
              height: "44px",
            }}
          />
        </ListItem>

        <CloseOutlinedIcon
          onClick={toggleDrawer}
          style={{ fontSize: 25, left: 0, display: "flex" }}
        />
      </Box>

      <Divider />
      {pages.map((page) => (
        <StyledListItem
          primaryText={page.title}
          onClick={() => onDrawerClick(page)}
          isBold
        />
      ))}
      <Divider />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "10",
          alignItems: "center",
        }}
        onClick={() => setOpenAccount(!openAccount)}
      >
        <StyledListItem
          primaryText="ACCOUNT"
          onClick={() => setOpenAccount(!openAccount)}
          isBold
        />
        {openAccount ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>
      {openAccount && (
        <>
          {currentUser ? (
            <Box style={{ marginLeft: 10 }}>
              <StyledListItem
                primaryText="- PROFILE"
                onClick={() => onDrawerClick({ navigateTo: "profile" })}
                sub
              />
              <StyledListItem
                primaryText="- LOGOUT"
                onClick={() => {
                  localStorage.setItem("currentUser", null);
                  onDrawerClick({ navigateTo: "" });
                }}
                sub
              />{" "}
            </Box>
          ) : (
            <Box style={{ marginLeft: 10 }}>
              <StyledListItem
                primaryText="- LOGIN"
                onClick={() => onDrawerClick({ navigateTo: "signin" })}
                sub
              />
              <StyledListItem
                primaryText="- CREATE ACCOUNT"
                onClick={() => onDrawerClick({ navigateTo: "FAQ" })}
                sub
              />{" "}
            </Box>
          )}
        </>
      )}
      <Divider />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "5",
          alignItems: "center",
        }}
        onClick={() => setOpenSupport(!openSupport)}
      >
        <StyledListItem
          primaryText="SUPPORT"
          isBold
          onClick={() => setOpenSupport(!openSupport)}
        />

        {openSupport ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>

      {openSupport && (
        <Box style={{ marginLeft: 10 }}>
          <StyledListItem
            primaryText="- CONTACT US"
            onClick={() => onDrawerClick({ navigateTo: "contact-us" })}
            sub
          />
          <StyledListItem
            primaryText="- FAQ"
            onClick={() => onDrawerClick({ navigateTo: "FAQ" })}
            sub
          />{" "}
        </Box>
      )}
      <Divider />
      <ListItem
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 10,
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <InstagramIcon
          style={{ color: "black", cursor: "pointer" }}
          onClick={() =>
            window.open(
              "https://www.instagram.com/skrbs.eg?igsh=MWR0NW1taGZlczA5bw==",
              "_blank"
            )
          }
        />
        <FacebookRoundedIcon
          style={{ color: "black", cursor: "pointer" }}
          onClick={() =>
            window.open(
              "https://www.facebook.com/profile.php?id=61563776767634",
              "_blank"
            )
          }
        />
      </ListItem>
    </List>
  );
};
export default OnMobDrawer;
