import React, { useCallback, useMemo, useState } from "react";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../api";
import { CircularProgress, TextField } from "@mui/material";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

const theme = createTheme();
const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};
export default function UserResetPasswordForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  const handleSendResetPassword = (event) => {
    event.preventDefault();
    setError("");
    setSuccess(false);
    const data = new FormData(event.currentTarget);
    setLoading(true);
    axios
      .post(`${API_URL}/users/forget_password`, data)
      .then((response) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        setError("Failed to send reset link!");
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="sm"
        style={{ backgroundColor: "#F6F6F6", padding: 20 }}
        sx={{ marginTop: { xs: 5, md: 10 } }}
      >
        {!isSuccess ? (
          <>
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{ fontSize: { xs: 20, md: 30 } }}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: " #28282",
                }}
              >
                Forgot password
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                onSubmit={handleSendResetPassword}
                noValidate
                sx={{ mt: 1, pl: 2, pr: 2 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={customTextFieldStyling}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "black", color: "white" }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  {loading ? (
                    <CircularProgress style={{ color: "white" }} />
                  ) : (
                    "Reset"
                  )}
                </Button>
                <Typography
                  sx={{ fontSize: { xs: 13, md: 15 } }}
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    color: " #28282",
                  }}
                >
                  * If your account exists, just enter your email, and we'll
                  send you instructions to reset your password.
                </Typography>
                {error && (
                  <Typography
                    style={{ marginTop: 3, marginBottom: 3, color: "red" }}
                  >
                    Something went wrong
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Container
            component="main"
            maxWidth="sm"
            style={{
              backgroundColor: "#F6F6F6",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            sx={{ marginTop: { xs: 3, md: 5 } }}
          >
            <MarkEmailReadOutlinedIcon style={{ fontSize: "300%" }} />
            <Typography
              sx={{ fontSize: { xs: 15, md: 20 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: " #28282",
              }}
            >
              If we find an account associated with your email, we'll send you
              an email with detailed instructions on how to reset your password.
              Kindly check your inbox shortly.
            </Typography>
          </Container>
        )}
      </Container>
    </ThemeProvider>
  );
}
