import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Typography,
} from "@mui/material";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import EditCategory from "./EditCategory";
import { API_URL } from "../../../../api";
import axios from "axios";
import ConfirmDelete from "../../../../components/ConfirmDelete";
import DeleteIcon from "@mui/icons-material/Delete";

const CategoryCard = ({ category, refetch, index }) => {
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [currentRow, setCurrentRow] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleDeleteCategory = () =>
    axios
      .delete(`${API_URL}/products/subcategory/${currentRow.id}`)
      .then(() => {
        setOpenConfirmDelete(false);
        refetch();
      })
      .catch((err) => {});
  return (
    <Card
      style={{
        padding: 20,
        position: "relative",
        top: 20,
        boxShadow: "none",
        width: 350,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: 20,
            color: "#000000",
          }}
        >
          - {category?.name}
        </Typography>
        <Button
          onClick={() => setOpenEditCategory(true)}
          style={{ padding: 0, color: "black" }}
        >
          <EditIcon style={{ fontSize: 15 }} />
        </Button>
        <Button
          onClick={() => {
            setOpenConfirmDelete(true);
            setCurrentRow(category);
          }}
        >
          <DeleteIcon style={{ color: "red", fontSize: 18 }} />
        </Button>
      </div>

      <Dialog
        onClose={() => setOpenEditCategory(false)}
        open={openEditCategory}
      >
        <EditCategory
          category={category}
          setOpenEditCategory={setOpenEditCategory}
          refetch={refetch}
        />
      </Dialog>
      <Dialog
        onClose={() => setOpenConfirmDelete(false)}
        open={openConfirmDelete}
      >
        <ConfirmDelete
          onClose={() => setOpenConfirmDelete(false)}
          text={currentRow.name}
          onDelete={(e) => handleDeleteCategory(e)}
        />
      </Dialog>
    </Card>
  );
};
export default CategoryCard;
