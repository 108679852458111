import { Grid, Typography } from "@mui/material";
import skrbsLogo from "../../assets/images/skrbsLogoWhite.png";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import { useNavigate } from "react-router";
const getHelpPages = [
  { title: "FAQ", navigateTo: "FAQ" },
  { title: "Size guide", navigateTo: "size-guide" },
  { title: "Returns & exchange", navigateTo: "FAQ" },
  { title: "Contact Us", navigateTo: "contact-us" },
];

const ourCompanyPages = [
  { title: "Careers", navigateTo: "" },
  { title: "About us", navigateTo: "about-us" },
];
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "relative",
        left: 0,
        bottom: 10,
        top: 70,
        width: "100%",
        backgroundColor: "#282828",
        flexShrink: 0,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ padding: { xs: 0, lg: 8 }, paddingTop: { xs: 5 } }}
      >
        <Grid
          item
          xs={12}
          md={8}
          lg={8}
          sx={{
            alignItems: { xs: "center", md: "flex-start" },
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 10,
          }}
        >
          <img
            onClick={() => navigate("/")}
            src={skrbsLogo}
            alt="logo"
            style={{
              width: "89px",
              height: "44px",
              paddingLeft: 15,
              cursor: "pointer",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "15%",
            }}
          >
            <InstagramIcon
              style={{ color: "white", cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/skrbs.eg?igsh=MWR0NW1taGZlczA5bw==",
                  "_blank"
                )
              }
            />
            <FacebookRoundedIcon
              style={{ color: "white", cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=61563776767634",
                  "_blank"
                )
              }
            />
            {/* <TwitterIcon style={{ color: "white", cursor: "pointer" }} />
            <PinterestIcon style={{ color: "white", cursor: "pointer" }} /> */}
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <Typography
            sx={{
              fontSize: { xs: 15, md: 20 },
            }}
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "bold",
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            Support
          </Typography>
          {getHelpPages.map((page) => {
            return (
              <Typography
                onClick={() => navigate(page.navigateTo)}
                sx={{
                  fontSize: { xs: 14, md: 16 },
                }}
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  textAlign: "center",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
              >
                {page.title}
              </Typography>
            );
          })}
        </Grid>

        <Grid item xs={12} md={6} lg={2}>
          <Typography
            sx={{
              fontSize: { xs: 15, md: 20 },
            }}
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "bold",
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            Our Company{" "}
          </Typography>
          {ourCompanyPages.map((page) => {
            return (
              <Typography
                onClick={() => navigate(page.navigateTo)}
                sx={{
                  fontSize: { xs: 14, md: 16 },
                }}
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  textAlign: "center",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
              >
                {page.title}
              </Typography>
            );
          })}
        </Grid>
      </Grid>{" "}
    </div>
  );
};
export default Footer;
