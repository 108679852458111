import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_URL } from "../../api";
import { Box } from "@mui/material";

const globalStyles = `
  *:focus {
    outline: none;
  }
`;
const GlobalStyles = () => {
  return <style>{globalStyles}</style>;
};

const CustomArrow = ({ direction, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "50%",
        fontSize: 30,
      }}
    >
      {direction === "up" ? "↑" : "↓"}
    </div>
  );
};

const VerticalCarousel = ({ images, setSelectedImage }) => {
  const sliderRef = useRef(null);

  const settings = {
    vertical: true,
    verticalSwiping: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div>
      <GlobalStyles />

      <div style={{ marginBottom: "10px" }}>
        <CustomArrow
          direction="up"
          onClick={() => sliderRef.current.slickPrev()}
        />
      </div>

      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <Box
            key={index}
            style={{
              width: 200,
              height: 174,
              display: "flex",
              border: "1px solid transparent",
              transition: "border-color 0.3s",
              justifyContent: "center",
              outline: "none",
            }}
            onClick={() => setSelectedImage(image)}
            onMouseOver={(e) => {
              e.target.style.borderColor = "black";
            }}
            onMouseOut={(e) => {
              e.target.style.borderColor = "transparent";
            }}
            tabIndex={-1}
          >
            <img
              alt=""
              src={IMAGE_URL + "/" + image}
              style={{
                maxWidth: "100%",
                height: "auto",
                top: 0,
                outline: "none",
              }}
              tabIndex={-1}
            />
          </Box>
        ))}
      </Slider>

      <div style={{ marginTop: "10px" }}>
        <CustomArrow
          direction="down"
          onClick={() => sliderRef.current.slickNext()}
        />
      </div>
    </div>
  );
};

export default VerticalCarousel;
