import { Grid, Box, Typography } from "@mui/material";
import Main from "../../../../assets/images/skrbsHomeMain.png";
import FeaturedItems from "./Components/FeaturedItems/FeaturedItems";
import MainWebText from "./Components/MainWebText/MainWebText";
import NewCollectionSection from "./Components/NewCollectionSection";
const MainOnWeb = () => {
  return (
    <div style={{ marginLeft: 42, marginRight: 42 }}>
      <Grid container style={{ backgroundColor: "#ECECEC" }}>
        <Grid item xs={6}>
          <MainWebText />
        </Grid>
        <Grid item xs={6}>
          <Box
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <img
              alt=""
              src={Main}
              style={{
                maxWidth: "100%",
                height: "auto",
                top: 0,
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <NewCollectionSection />

      <FeaturedItems />
    </div>
  );
};
export default MainOnWeb;
