import { Grid } from "@material-ui/core";
import LeftContainer from "./Components/LeftContainer";
import RightContainer from "./Components/RightContainer/RightContainer";
import Box from "@mui/material/Box";
import skrbsLogo from "../../assets/images/skrbsLogo.png";
import OnMobRightContainer from "./Components/OnMobRightContainer/OnMobRightContainer";
import { useEffect, useState } from "react";
import StripePayment from "../../components/StripePayment";

const Checkout = () => {
  const [shippingFees, setShippingFees] = useState(0);
  const [shippingEstimate, setShippingEstimate] = useState(0);

  const [isVisaPay, setIsVisaPay] = useState(false);
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    document.title = "SKRBS - Checkout";
    return () => {
      document.title = "SKRBS";
    };
  }, []);
  return isVisaPay ? (
    <>
      <StripePayment
        order_values={orderData}
        price={orderData?.totalPrice}
        email={orderData?.user?.email}
      />
    </>
  ) : (
    <>
      <Box
        sx={{
          paddingLeft: { xs: "14px" },
          paddingTop: { xs: "21px" },
          display: { xs: "flex", md: "none" },
        }}
      >
        <img
          src={skrbsLogo}
          alt="logo"
          style={{
            width: "77px",
            height: "44px",
          }}
        />
      </Box>
      <OnMobRightContainer shippingFees={shippingFees} />
      <Grid container>
        <Grid item xs={12} md={7} lg={7}>
          <LeftContainer
            setShippingFees={setShippingFees}
            setOrderData={setOrderData}
            setIsVisaPay={setIsVisaPay}
            setShippingEstimate={setShippingEstimate}
          />
        </Grid>

        <Grid item xs={12} md={5} lg={5}>
          <Box
            sx={{
              display: { xs: "none", md: "grid" },
            }}
          >
            <RightContainer
              shippingFees={shippingFees}
              shippingEstimate={shippingEstimate}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Checkout;
