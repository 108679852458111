import { Box, Button, Typography } from "@mui/material";
import Main from "../../../../assets/images/skrbsHomeMain.png";
import FeaturedItems from "./Components/FeaturedItems/FeaturedItems";
import NewCollection from "./Components/NewCollection/NewCollection";
import { useNavigate } from "react-router";
const MainOnMob = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        style={{
          backgroundColor: "#ECECEC",
          paddingBottom: 30,
        }}
      >
        <Box
          style={{
            display: "flex",
          }}
        >
          <img
            alt=""
            src={Main}
            style={{
              maxWidth: "100%",
              height: "auto",
              marginTop: 40,
            }}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 300,
              color: "#282828",
              fontSize: 30,
            }}
          >
            WE ARE
            <span style={{ fontWeight: 600 }}> SKRBS</span>
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            // flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 300,
              color: "#282828",
              fontSize: 15,
            }}
          >
            Designed by Professionals for Professionals
          </Typography>
        </Box>
      </Box>
      <FeaturedItems />
      <NewCollection />
    </Box>
  );
};
export default MainOnMob;
