import { ListItem, ListItemText } from "@mui/material";

const StyledListItem = ({ onClick, primaryText, sub }) => {
  return (
    <ListItem button onClick={onClick}>
      <ListItemText
        primaryTypographyProps={{
          style: {
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: sub ? 15 : 18,
            color: "#282828",
          },
        }}
        primary={primaryText}
      />
    </ListItem>
  );
};
export default StyledListItem;
