import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Form, Formik } from "formik";
import FormField from "../../components/FormField";
import axios from "axios";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Box, Button, CircularProgress, Container } from "@mui/material";
import Mailto from "../../components/Mailto/Mailto";
import { API_URL } from "../../api";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [success, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const initialValues = useMemo(
    () => ({
      email: "",
      name: "",
      orderNumber: "",
      message: "",
    }),
    []
  );

  useEffect(() => {
    document.title = "SKRBS - Contact us";
    return () => {
      document.title = "SKRBS";
    };
  }, []);
  return success ? (
    <Container
      component="main"
      maxWidth="sm"
      style={{
        backgroundColor: "#F6F6F6",
        padding: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      sx={{ marginTop: { xs: 3, md: 5 } }}
    >
      <MarkEmailReadOutlinedIcon style={{ fontSize: "300%" }} />
      <Typography
        sx={{ fontSize: { xs: 15, md: 20 } }}
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          color: " #28282",
        }}
      >
        Thank you for contacting us , our team will get back to you shortly.
      </Typography>
    </Container>
  ) : (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Box
        style={{
          background: "#FFFFFF",
        }}
        sx={{
          width: { xs: "80vw", md: "50vw" },
          mt: 3,
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: 25,
              md: 25,
            },
          }}
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            color: "#282828",
            paddingTop: 20,
          }}
        >
          Contact us
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: 17,
              md: 18,
            },
          }}
          style={{
            fontFamily: "Montserrat",
            color: "#282828",
            marginBottom: 20,
            fontWeight: 500,
          }}
        >
          Feel free to reach out anytime, and we'll respond as soon as possible.
        </Typography>
        <Grid container sx={{ mb: 3 }}>
          <Grid
            item
            xs={6}
            style={{ display: "flex", alignItems: "center", gap: 5 }}
          >
            <EmailOutlinedIcon />
            <Mailto emails={["info@skrbs.com"]}>info@skrbs.com</Mailto>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", alignItems: "center", gap: 5 }}
          >
            <LocalPhoneOutlinedIcon />
            01020748265
          </Grid>
        </Grid>
        <Formik initialValues={initialValues}>
          {({ values }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type="input"
                    name={`email`}
                    label="Email"
                    variant="outlined"
                    fullwidth
                    customizedStyling={customTextFieldStyling}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type="input"
                    name={`name`}
                    label="Name"
                    variant="outlined"
                    fullwidth
                    customizedStyling={customTextFieldStyling}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type="input"
                    name={`orderNumber`}
                    label="Order number (Optional)"
                    variant="outlined"
                    fullwidth
                    customizedStyling={customTextFieldStyling}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type="input"
                    name={`message`}
                    label="Message"
                    variant="outlined"
                    fullwidth
                    customizedStyling={customTextFieldStyling}
                    multiline
                  />
                </Grid>
              </Grid>

              <Box sx={{ width: { xs: "80vw", md: "50vw" }, mt: 5 }}>
                <Button
                  style={{
                    backgroundColor: "black",
                    color: "white",
                  }}
                  fullWidth
                  onClick={() => {
                    setLoading(true);

                    axios
                      .post(`${API_URL}/users/contact-us`, {
                        email: values.email,
                        name: values.name,
                        message: values.message,
                        order_number: values.orderNumber,
                      })
                      .then((response) => {
                        setLoading(false);
                        setIsSuccess(true);
                      })
                      .catch((err) => {
                        setLoading(false);

                        setError(true);
                      });
                  }}
                >
                  {loading ? (
                    <CircularProgress style={{ color: "white" }} />
                  ) : (
                    "SEND"
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};
export default ContactUs;
