import { Box } from "@mui/material";
import MainOnMob from "./Components/MainOnMob";
import MainOnWeb from "./Components/MainOnWeb";
import FeaturedItems from "./Components/MainOnWeb/Components/FeaturedItems/FeaturedItems";
import ScrubsQualifications from "./Components/ScrubsQualifications/ScrubsQualifications";

const HomePage = () => {
  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <MainOnWeb />
      </Box>
      <Box sx={{ display: { xs: "initial", md: "none" } }}>
        <MainOnMob />
      </Box>
      <ScrubsQualifications />
    </>
  );
};
export default HomePage;
