import React from "react";
import { Box, CircularProgress } from "@mui/material";
import skrbsLogo from "../../assets/images/skrbsLogo.png";

const CircularLoaderWithLogo = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        size={100}
        thickness={1}
        sx={{
          position: "absolute",
          color: "black",
          animation: "rotate 2s linear infinite",
        }}
      />
      =
      <Box
        component="img"
        src={skrbsLogo}
        alt="Logo"
        sx={{
          width: 60,
          height: 60,
          zIndex: 1,
        }}
      />
    </Box>
  );
};

export default CircularLoaderWithLogo;
