import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@material-ui/core";

export default function TotalOrders({ total_count = 0, text }) {
  return (
    <Grid
      container
      style={{ padding: 10, display: "flex", alignItems: "center" }}
    >
      <Grid item xs={12}>
        <Typography>{text}</Typography>
      </Grid>
      <Grid item>
        <Typography component="p" variant="h2">
          {total_count}
        </Typography>
      </Grid>
    </Grid>
  );
}
