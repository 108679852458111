import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Field, Form, Formik } from "formik";
import FormField from "../../../../components/FormField";
import axios from "axios";
import { API_URL } from "../../../../api";
import { Box, Button, CircularProgress } from "@mui/material";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};

const CustomCheckBoxField = ({ label, id, values, setFieldValue }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <label>
      <Field
        type="checkbox"
        style={{ margin: 1 }}
        checked={isChecked}
        onChange={(event) => {
          const newValue = event.target.checked;
          setIsChecked(newValue);
          if (newValue) {
            const newPermissions = [...values.permissions, id];
            setFieldValue("permissions", newPermissions);
          } else {
            const newPermissions = values.permissions.filter(
              (permission) => permission !== id
            );
            setFieldValue("permissions", newPermissions);
          }
        }}
      />
      {label}
    </label>
  );
};
const AddEmployee = ({ refetch, setOpenAddEmployee }) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMessage] = useState(false);
  const [error, setError] = useState("");

  const initialValues = useMemo(
    () => ({
      email: "",
      password: "1234",
      type: "Admin",

      first_name: "",
      last_name: "",
      permissions: [],
    }),
    []
  );

  const handleAddAdmin = async (event, values) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${API_URL}/admins/`, values)
      .then((response) => {
        setLoading(false);
        refetch();
        setOpenAddEmployee(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.email[0] || "Something went wrong !");
      });
  };
  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 50,
        width: "100%",
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Typography
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Personal Information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`email`}
                  label="Email"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="input"
                  name={`first_name`}
                  label="First name"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="input"
                  name={`last_name`}
                  label="Last Name"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  required
                />
              </Grid>
            </Grid>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              Permissions{" "}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <CustomCheckBoxField
                  id={1}
                  values={values}
                  setFieldValue={setFieldValue}
                  label="Can manage dashboard"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomCheckBoxField
                  id={7}
                  values={values}
                  setFieldValue={setFieldValue}
                  label="Can manage users"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomCheckBoxField
                  id={2}
                  values={values}
                  setFieldValue={setFieldValue}
                  label="Can manage orders"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomCheckBoxField
                  id={3}
                  values={values}
                  setFieldValue={setFieldValue}
                  label="Can manage products"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomCheckBoxField
                  id={10}
                  values={values}
                  setFieldValue={setFieldValue}
                  label="Can manage sale"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomCheckBoxField
                  id={9}
                  values={values}
                  setFieldValue={setFieldValue}
                  label="Can manage employees"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12}>
                <CustomCheckBoxField
                  id={4}
                  values={values}
                  setFieldValue={setFieldValue}
                  label="Can manage featured products"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12}>
                <CustomCheckBoxField
                  id={5}
                  values={values}
                  setFieldValue={setFieldValue}
                  label="Can manage categories"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12}>
                <CustomCheckBoxField
                  id={6}
                  values={values}
                  setFieldValue={setFieldValue}
                  label="Can manage promocodes"
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12}>
                <CustomCheckBoxField
                  id={8}
                  values={values}
                  setFieldValue={setFieldValue}
                  label="Can manage shipping fees"
                />
              </Grid>{" "}
            </Grid>

            {error && error?.includes("email") && (
              <Typography style={{ color: "red", paddingLeft: 10 }}>
                {error}
              </Typography>
            )}

            <div style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ mt: 3, ml: 1 }}
                style={{ marginTop: 30, backgroundColor: "black" }}
                onClick={(event) => handleAddAdmin(event, values)}
              >
                {loading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress style={{ color: "white" }} />
                  </Box>
                ) : (
                  "Save"
                )}
              </Button>
              {msg && (
                <Typography style={{ paddingLeft: 10 }}>{msg}</Typography>
              )}{" "}
              {error && !error?.includes("email") && (
                <Typography style={{ color: "red", paddingLeft: 10 }}>
                  {error}
                </Typography>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default AddEmployee;
