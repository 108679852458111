import { Box, Chip, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { IMAGE_URL } from "../../../../api";

const ProductCard = ({ product }) => {
  const [onHover, setOnHover] = useState(null);
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => navigate(`/product-details/${product.id}`)}
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        onMouseOver={() => setOnHover(true)}
        onMouseOut={() => setOnHover(false)}
        style={{
          position: "relative", // Needed for absolute positioning of Chip
          width: "100%",
          objectFit: "contain",
        }}
      >
        <img
          alt=""
          src={
            !onHover
              ? product?.colors[0]?.images &&
                IMAGE_URL + "/" + product?.colors[0]?.images[0]
              : product?.colors[0]?.images &&
                IMAGE_URL + "/" + product?.colors[0]?.images[1]
              ? IMAGE_URL + "/" + product?.colors[0]?.images[1]
              : product?.colors[0]?.images &&
                IMAGE_URL + "/" + product?.colors[0]?.images[0]
          }
          style={{
            maxWidth: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        {product.tag && (
          <Chip
            label={product.tag}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "transparent",
              border: "1px solid black",
            }}
          />
        )}
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize: 18,
            color: "#000000",
          }}
        >
          {product?.name.length > 15
            ? `${product?.name.slice(0, 15)}...`
            : product?.name}
        </Typography>
      </Box>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: 300,
          fontSize: 15,
          color: "#000000",
        }}
      >
        {product?.colors?.length} colors
      </Typography>
      <Box style={{ display: "flex", flexDirection: "row", columnGap: 20 }}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: " #282828",
            textDecoration: product?.sale_price > 0 && "line-through",
          }}
        >
          LE {product?.price}
        </Typography>
        {product?.sale_price > 0 && (
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: " red",
            }}
          >
            LE {product?.sale_price}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
export default ProductCard;
