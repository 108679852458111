import { useCallback, useEffect, useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Typography } from "@mui/material";
import RightContainer from "../RightContainer/RightContainer";

const OnMobRightContainer = ({ shippingFees }) => {
  const [showOrderSummary, setShowOrderSummary] = useState(false);

  const toggleShowSummary = useCallback(() => {
    setShowOrderSummary(!showOrderSummary);
  }, [setShowOrderSummary, showOrderSummary]);

  return (
    <>
      <Box
        sx={{
          marginTop: 3,
          display: { xs: "flex", md: "none" },
          paddingTop: { xs: "21px" },
          paddingBottom: { xs: "21px" },
          background: "#F6F6F6",
          width: "100vw",
        }}
      >
        <ShoppingCartIcon style={{ marginLeft: 32 }} />
        <Typography
          style={{ fontFamily: "Montserrat", marginLeft: 4, fontWeight: 500 }}
          onClick={toggleShowSummary}
        >
          {showOrderSummary ? " Hide " : "Show "}
          order summary
        </Typography>
        {showOrderSummary ? (
          <KeyboardArrowUpIcon onClick={toggleShowSummary} />
        ) : (
          <KeyboardArrowDownIcon onClick={toggleShowSummary} />
        )}
      </Box>
      {showOrderSummary && <RightContainer onMob shippingFees={shippingFees} />}
    </>
  );
};
export default OnMobRightContainer;
