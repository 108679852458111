import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";

const FloatingMessengerButton = () => {
  const pageId = "61563776767634";
  const openMessengerChat = () => {
    const url = `https://m.me/${pageId}`;
    window.open(url, "_blank");
  };

  const buttonStyle = {
    position: "fixed",
    bottom: "20px",
    left: "20px",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#0084ff",
    color: "white",
    textAlign: "center",
    fontSize: "30px",
    lineHeight: "50px",
    cursor: "pointer",
    zIndex: "9999",
  };

  return (
    <div style={buttonStyle} onClick={openMessengerChat}>
      <FontAwesomeIcon icon={faFacebookMessenger} />
    </div>
  );
};

export default FloatingMessengerButton;
