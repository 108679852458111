import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";

const MainWebText = () => {
  const navigate = useNavigate();

  return (
    <>
      <Typography
        sx={{
          fontSize: { md: 30, lg: 50 },
          marginLeft: { md: 30 },
          marginTop: { md: 9 },
        }}
        style={{
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 300,
          color: "#282828",
        }}
      >
        WE ARE
      </Typography>
      <Typography
        sx={{
          fontSize: { md: 30, lg: 50 },
          marginLeft: { xs: 1, md: 15 },
          marginTop: { xs: 0, md: 0 },
        }}
        style={{
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "bold",
          // fontSize: 70,
          color: "#282828",
        }}
      >
        SKRBS
      </Typography>
      <Typography
        sx={{
          fontSize: { md: 15, lg: 20 },
          marginLeft: { xs: 1, md: 15 },
          marginTop: { xs: 0, md: 3 },
        }}
        style={{
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 500,
          color: "#282828",
        }}
      >
        Designed by Professionals for Professionals
      </Typography>

      <Button
        sx={{
          fontSize: { md: 20, lg: 20 },
          marginLeft: { md: 30, lg: 40 },
          height: { md: 50, lg: 40 },
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.backgroundColor = "#4B4B4B";
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.backgroundColor = "#282828";
        }}
        onClick={() => navigate("/products")}
        style={{
          marginTop: 57,
          width: 240,
          background: "#282828",
          borderRadius: 4,
          color: "white",
          fontFamily: "Montserrat",
          fontWeight: 400,
        }}
      >
        shop
      </Button>
    </>
  );
};
export default MainWebText;
