import { Badge, Box, Grid, Typography } from "@material-ui/core";

const ProductsList = ({ product }) => {
  return (
    <Grid container style={{ marginTop: 60 }}>
      <Grid item xs={3} md={3} lg={3} style={{ marginBottom: 5 }}>
        <Badge
          badgeContent={
            <span
              style={{
                backgroundColor: "grey",
                color: "white",
                fontWeight: "bold",
                width: "13px",
                height: "13px",
                borderRadius: "50%",
                padding: 3,
                textAlign: "center",
              }}
            >
              {product.quantity}
            </span>
          }
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              backgroundColor: "#ECECEC",
            }}
          >
            <img
              alt=""
              src={product.image}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Box>
        </Badge>
      </Grid>
      <Grid item xs={5} md={5} lg={5}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: 15,
            color: "#000000",
            marginLeft: 15,
          }}
        >
          {product.title}
        </Typography>
      </Grid>
      <Grid item xs={3} md={3} lg={3}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontSize: 15,
            color: "#000000",
          }}
        >
          LE {product.price}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ProductsList;
