import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import CheckroomOutlinedIcon from "@mui/icons-material/CheckroomOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LogoutIcon from "@mui/icons-material/Logout";
import BarChartSharpIcon from "@mui/icons-material/BarChartSharp";
import AdminPanelSettingsSharpIcon from "@mui/icons-material/AdminPanelSettingsSharp";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StarBorderPurple500SharpIcon from "@mui/icons-material/StarBorderPurple500Sharp";
import DrawerRecord from "./Components/DrawerRecord/DrawerRecord";
import CategoryIcon from "@mui/icons-material/Category";
import PercentIcon from "@mui/icons-material/Percent";
import LoyaltyIcon from "@mui/icons-material/Loyalty";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AdminNavbar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const jsonString = localStorage.getItem("currentAdmin");
  const currentAdmin = JSON.parse(jsonString);

  const can_manage_orders = currentAdmin.permissions?.some(
    (permission) => permission.permission.description === "can_manage_orders"
  );
  const can_manage_dashboard = currentAdmin.permissions?.some(
    (permission) => permission.permission.description === "can_manage_dashboard"
  );
  const can_manage_products = currentAdmin.permissions?.some(
    (permission) => permission.permission.description === "can_manage_products"
  );
  const can_manage_featured_products = currentAdmin.permissions?.some(
    (permission) =>
      permission.permission.description === "can_manage_featured_products"
  );

  const can_manage_users = currentAdmin.permissions?.some(
    (permission) => permission.permission.description === "can_manage_users"
  );
  const can_manage_employees = currentAdmin.permissions?.some(
    (permission) => permission.permission.description === "can_manage_employees"
  );
  const can_manage_shipping_fees = currentAdmin.permissions?.some(
    (permission) =>
      permission.permission.description === "can_manage_shipping_fees"
  );
  const can_manage_categories = currentAdmin.permissions?.some(
    (permission) =>
      permission.permission.description === "can_manage_categories"
  );
  const can_manage_promocodes = currentAdmin.permissions?.some(
    (permission) =>
      permission.permission.description === "can_manage_promocodes"
  );
  const can_manage_sale = currentAdmin.permissions?.some(
    (permission) => permission.permission.description === "can_manage_sale"
  );
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        open={open}
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon
              style={{ color: "black" }}
              sx={{
                ...(open && { display: "none" }),
              }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{ color: "black" }} />
            ) : (
              <ChevronLeftIcon
                style={{ color: "black" }}
                sx={{
                  ...(!open && { display: "none" }),
                }}
              />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {can_manage_dashboard && (
            <DrawerRecord
              open={open}
              icon={<BarChartSharpIcon style={{ fontSize: "30px" }} />}
              title="Dashboard"
              navigateTo="/admin/dashboard"
            />
          )}
          {can_manage_orders && (
            <DrawerRecord
              open={open}
              icon={<ShoppingBagOutlinedIcon style={{ fontSize: "30px" }} />}
              title="Orders"
              navigateTo="/admin/orders"
            />
          )}
          {can_manage_products && (
            <DrawerRecord
              open={open}
              icon={<CheckroomOutlinedIcon style={{ fontSize: "30px" }} />}
              title="Products"
              navigateTo="/admin/products"
            />
          )}
          {can_manage_sale && (
            <DrawerRecord
              open={open}
              icon={<LoyaltyIcon style={{ fontSize: "30px" }} />}
              title="Sale"
              navigateTo="/admin/sale"
            />
          )}
          {can_manage_featured_products && (
            <DrawerRecord
              open={open}
              icon={
                <StarBorderPurple500SharpIcon style={{ fontSize: "30px" }} />
              }
              title="Featured Products"
              navigateTo="/admin/featured"
            />
          )}
          {can_manage_categories && (
            <DrawerRecord
              open={open}
              icon={<CategoryIcon style={{ fontSize: "30px" }} />}
              title="Categories"
              navigateTo="/admin/categories"
            />
          )}
          {can_manage_categories && (
            <DrawerRecord
              open={open}
              icon={<CategoryIcon style={{ fontSize: "30px" }} />}
              title="Sub-Categories"
              navigateTo="/admin/subcategories"
            />
          )}
          {can_manage_promocodes && (
            <DrawerRecord
              open={open}
              icon={<PercentIcon style={{ fontSize: "30px" }} />}
              title="Promocodes"
              navigateTo="/admin/promocode"
            />
          )}
          {can_manage_users && (
            <DrawerRecord
              open={open}
              icon={<GroupsIcon style={{ fontSize: "30px" }} />}
              title="Users"
              navigateTo="/admin/users"
            />
          )}
          {can_manage_shipping_fees && (
            <DrawerRecord
              open={open}
              icon={<LocalShippingIcon style={{ fontSize: "30px" }} />}
              title="Shipping Fees"
              navigateTo="/admin/shippingFees"
            />
          )}
          {can_manage_employees && (
            <DrawerRecord
              open={open}
              icon={
                <AdminPanelSettingsSharpIcon style={{ fontSize: "30px" }} />
              }
              title="Employees"
              navigateTo="/admin/employees"
            />
          )}{" "}
        </List>
        <Divider />
        <List>
          <DrawerRecord
            open={open}
            icon={<PermIdentityIcon style={{ fontSize: "30px" }} />}
            title="Profile"
            navigateTo="/admin/profile"
          />
          <DrawerRecord
            open={open}
            icon={<LogoutIcon style={{ fontSize: "30px" }} />}
            title="Logout"
            navigateTo="/admin"
          />
        </List>
      </Drawer>
    </Box>
  );
}
