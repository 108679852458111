import { useCallback, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import skrbsLogo from "../../assets/images/skrbsLogo.png";
import { Drawer, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@mui/icons-material/Person";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { useNavigate } from "react-router";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Cart from "../../pages/Cart/Cart";
import OnMobDrawer from "./Components/OnMobDrawer/OnMobDrawer";
const pages = [
  { title: "HOME", navigateTo: "/" },
  { title: "MEN", navigateTo: "/products/?category=men" },
  { title: "WOMEN", navigateTo: "/products/?category=women" },

  { title: "ABOUT US", navigateTo: "/about-us" },
];

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 400,
  },
  icons: {
    color: "#282828",
    width: "30px",
    height: "33px",
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const jsonString = localStorage.getItem("currentUser");
  const currentUser = jsonString && JSON.parse(jsonString);

  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [setDrawerOpen, drawerOpen]);

  const onDrawerClick = useCallback(
    (page) => {
      toggleDrawer();
      navigate(page.navigateTo);
    },
    [toggleDrawer, navigate]
  );

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <img
              onClick={() => navigate("/")}
              src={skrbsLogo}
              alt="logo"
              style={{
                width: "77px",
                height: "44px",
                paddingLeft: 102,
              }}
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer}
              color="black"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <img
              onClick={() => navigate("/")}
              src={skrbsLogo}
              alt="logo"
              style={{
                width: "77px",
                height: "44px",
                paddingLeft: 70,
              }}
            />
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingLeft: "317px",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => navigate(page.navigateTo)}
                sx={{ my: 2, color: "white", display: "block" }}
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#282828",
                  marginRight: "50px",
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          <PersonIcon
            className={classes.icons}
            onClick={() =>
              currentUser ? navigate("/profile") : navigate("/signin")
            }
          />
          <LocalMallOutlinedIcon
            className={classes.icons}
            onClick={() => setCartDrawerOpen(true)}
          />
        </Toolbar>
      </Container>
      <Drawer
        anchor="top"
        open={cartDrawerOpen}
        onClose={() => setCartDrawerOpen(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            mr: { xs: 5, md: 10 },
            mt: 5,
          }}
        >
          <CloseOutlinedIcon
            onClick={() => setCartDrawerOpen(false)}
            sx={{ fontSize: 30 }}
          />
        </Box>

        <Cart setCartDrawerOpen={setCartDrawerOpen} />
      </Drawer>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        className={classes.drawer}
        style={{ width: 400 }}
      >
        <OnMobDrawer
          pages={pages}
          toggleDrawer={toggleDrawer}
          onDrawerClick={onDrawerClick}
        />
      </Drawer>
    </AppBar>
  );
};
export default Navbar;
