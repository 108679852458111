import { Box, Grid } from "@mui/material";
import signinImage from "../../assets/images/signin.png";
import SigninForm from "./SignInform";

const Signin = () => {
  return (
    <Box
      sx={{
        ml: { xs: 1, md: 10 },
        mt: { xs: 1, md: 5 },
        mr: { xs: 1, md: 10 },
      }}
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <Grid container>
        <Grid item xs={0} md={6}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              width: { xs: "100%", md: "70%" },
            }}
            style={{
              width: "100%",
              backgroundColor: "#EAEAEA",
            }}
          >
            <img
              alt=""
              src={signinImage}
              style={{
                maxWidth: "100%",
                height: "auto",
                marginLeft: "10%",
                marginTop: "0%",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ backgroundColor: "#F6F6F6" }}>
          <SigninForm />
        </Grid>
      </Grid>
    </Box>
  );
};
export default Signin;
