import { Divider, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import ProductsList from "./Components/ProductsList/ProductsList";
import PromoCodeSection from "./Components/PromocodeSection/PromocodeSection";
import TotalPrice from "./Components/TotalPrice";
import PriceRecord from "./Components/TotalPrice/Components/PriceRecord/PriceRecord";

const RightContainer = ({ onMob = false, shippingFees, shippingEstimate }) => {
  const [cartDetails, setCartDetails] = useState([]);
  const [validPromocode, setValidPromocode] = useState();

  useEffect(() => {
    setCartDetails(JSON.parse(localStorage.getItem("cart")));
  }, []);
  return (
    <Box
      style={{
        paddingLeft: 40,
        background: "#F6F6F6",
        border: "1px solid rgba(169, 169, 169, 0.4)",
        height: onMob ? "auto" : "100vh",
        paddingBottom: onMob ? 10 : 0,
        position: !onMob && "fixed",
        overflowY: !onMob && "auto",
        width: !onMob && "40%",
      }}
    >
      {cartDetails.map((product) => {
        return <ProductsList product={product} />;
      })}
      <Divider style={{ margin: "30px 40px 30px 0px" }} />
      <PromoCodeSection
        validPromocode={validPromocode}
        setValidPromocode={setValidPromocode}
      />
      <Divider style={{ margin: "30px 40px 30px 0px" }} />
      <TotalPrice
        cartProducts={cartDetails}
        shippingFees={shippingFees}
        validPromocode={validPromocode}
      />
      <PriceRecord
        record={{
          title: "Shipping Estimate",
          price: shippingEstimate + " " + "days",
        }}
        isPrice={false}
        isBold
      />
    </Box>
  );
};
export default RightContainer;
