import { Grid, Typography } from "@material-ui/core";

const PriceRecord = ({ record, isBold = false, isPrice = true }) => {
  return (
    <Grid container>
      <Grid item xs={7} md={7} lg={8}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: isBold ? "bold" : 400,
            fontSize: 20,
            color: "#000000",
          }}
        >
          {record.title}
        </Typography>
      </Grid>
      <Grid item xs={3} md={3} lg={3}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: isBold ? "bold" : 400,
            fontSize: isBold ? 20 : 15,
            color: "#000000",
          }}
        >
          {isPrice ? "LE" : ""} {record.price}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default PriceRecord;
