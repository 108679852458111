import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const FloatingWhatsAppButton = () => {
  const openWhatsAppChat = () => {
    const url = `https://wa.me/+201020748265`;
    window.open(url, "_blank");
  };

  const buttonStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#25D366",
    color: "white",
    textAlign: "center",
    fontSize: "35px",
    lineHeight: "60px",
    cursor: "pointer",
    zIndex: "9999",
    justifyConent: "center",
  };

  return (
    <div style={buttonStyle} onClick={openWhatsAppChat}>
      <WhatsAppIcon fontSize="40px" />
    </div>
  );
};

export default FloatingWhatsAppButton;
