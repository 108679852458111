import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import ProductCard from "./Components/ProductCard/ProductCard";
import AddorEditProduct from "./Components/AddorEditProduct";
import axios from "axios";
import { API_URL } from "../../../api";
import { useNavigate } from "react-router";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  width: { xs: "100%", md: "60%" },
};

const Products = () => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filtered = rows.filter(
      (item) =>
        item?.title?.toLowerCase().includes(searchTerm) ||
        item?.category?.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
        setFilteredData(response.data);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentAdmin");

    !isLoggedIn ? navigate("/admin") : refetch();
  }, []);
  return loading ? (
    <Box
      style={{
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress
        style={{
          color: "black",
        }}
      />
    </Box>
  ) : (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", md: "flex-end" },
          flexDirection: "row",
          columnGap: 2,
        }}
      >
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
          onClick={() => setOpenAddProduct(true)}
        >
          Add
        </Button>
      </Box>
      <TextField
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginTop: 10, marginBottom: 20 }}
        sx={customTextFieldStyling}
      />
      <Grid container spacing={2}>
        {filteredData.map((product, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <ProductCard product={product} refetch={refetch} />
          </Grid>
        ))}
      </Grid>
      <Dialog onClose={() => setOpenAddProduct(false)} open={openAddProduct}>
        <AddorEditProduct
          setOpenAddProduct={setOpenAddProduct}
          refetch={refetch}
        />
      </Dialog>
    </>
  );
};
export default Products;
