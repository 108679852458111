import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, IMAGE_URL } from "../../../../../api";
import axios from "axios";
import ConfirmDelete from "../../../../../components/ConfirmDelete";

const ProductCard = ({ product, refetch }) => {
  const navigate = useNavigate();
  const [currentRow, setCurrentRow] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleRemoveFetchedProduct = () =>
    axios
      .patch(`${API_URL}/products/${currentRow.id}`, { is_featured: false })
      .then(() => {
        setOpenConfirmDelete(false);
        refetch();
      })
      .catch((err) => {});
  return (
    product && (
      <>
        <Box
          item
          style={{
            border: "1px solid #ECECEC",
            //   width: 350,
            height: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            cursor: "pointer",
          }}
          onClick={() => navigate(`/collection/${product?.id}`)}
          sx={{ width: { xs: 280, sm: 350 } }}
        >
          <img
            src={
              product?.colors[0]?.images &&
              IMAGE_URL + "/" + product?.colors[0]?.images[0]
            }
            style={{
              width: "70%",
              maxHeight: "100%",
            }}
            alt={product.title}
          />
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginTop: 20 }}
          columnGap={4}
        >
          <Grid item xs={5.5} md={6}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 18,
                color: "#000000",
              }}
            >
              {product?.name}
            </Typography>
          </Grid>
          <Grid item xs={2.5} md={3}>
            <Button
              onClick={() => {
                setCurrentRow(product);
                setOpenConfirmDelete(true);
              }}
              style={{
                padding: 5,
                color: "black",
                borderColor: "black",
              }}
              variant="outlined"
            >
              Remove
            </Button>
          </Grid>
        </Grid>

        <Dialog
          onClose={() => setOpenConfirmDelete(false)}
          open={openConfirmDelete}
        >
          <ConfirmDelete
            onClose={() => setOpenConfirmDelete(false)}
            text={currentRow.title}
            verb="remove fetaured"
            onDelete={(e) => handleRemoveFetchedProduct(e)}
          />
        </Dialog>
      </>
    )
  );
};
export default ProductCard;
