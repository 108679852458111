import { Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import PriceRecord from "./Components/PriceRecord/PriceRecord";

const TotalPrice = ({ cartProducts, shippingFees = 0, validPromocode }) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalProductsPrice, seTtotalProductsPrice] = useState(0);

  useEffect(() => {
    const productsPrice = cartProducts.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0);
    if (validPromocode) {
      const discountAmount = productsPrice * (validPromocode.percentage / 100);
      const discountedTotalPrice = productsPrice - discountAmount;
      seTtotalProductsPrice(discountedTotalPrice);
      setTotalPrice(discountedTotalPrice + shippingFees);
      localStorage.setItem("totalPrice", discountedTotalPrice + shippingFees);
    } else {
      seTtotalProductsPrice(productsPrice);
      setTotalPrice(productsPrice + shippingFees);
      localStorage.setItem("totalPrice", productsPrice + shippingFees);
    }
    localStorage.setItem("shippingFees", shippingFees);
  }, [cartProducts, shippingFees, validPromocode]);

  return (
    <>
      <PriceRecord record={{ title: "Subtotal", price: totalProductsPrice }} />
      <PriceRecord record={{ title: "Shipping", price: shippingFees }} />
      <Divider style={{ margin: "30px 40px 30px 0px" }} />
      <PriceRecord
        record={{ title: "Total", price: totalPrice }}
        isBold={true}
      />
    </>
  );
};
export default TotalPrice;
