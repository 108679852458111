import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import AboutUs from "../../assets/images/Aboutus.png";
import TextWithDifferentColors from "../../components/TextWithDifferentColors";
const AboutUsPage = () => {
  useEffect(() => {
    document.title = "SKRBS - About us";
    return () => {
      document.title = "SKRBS";
    };
  }, []);
  return (
    <>
      <Box
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <img
          alt=""
          src={AboutUs}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
        <Box
          sx={{
            display: { xs: "none", md: "inline" },
            top: { md: "57%" },
            width: { md: 480 },
            height: { md: 123 },
          }}
          style={{
            backgroundColor: "rgba(236, 236, 236, 0.6)",
            position: "absolute",
            left: "35%",
          }}
        >
          <Typography
            sx={{
              fontSize: { sx: 5, md: 25 },
            }}
            style={{
              color: "#282828",
              fontFamily: "Montserrat",
              fontWeight: 700,
              position: "relative",
              top: "23%",
              left: "25%",
            }}
          >
            Medical scrubs
          </Typography>
          <Typography
            sx={{
              fontSize: { sx: 5, md: 20 },
            }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: "#282828",
              position: "relative",
              left: "15%",
              top: "20%",
            }}
          >
            Get ready to love your scrubs
          </Typography>
          <Box
            sx={{
              width: { sx: 10, md: 303 },
              height: { sx: 10, md: 52 },
            }}
            style={{
              background: "#282828",
              borderRadius: 4,
              top: "27%",
              position: "relative",
              left: "15%",
            }}
          >
            <Typography
              sx={{
                fontSize: { sx: 5, md: 25 },
              }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                color: "#FFFFFF",
                position: "relative",
                left: "30%",
                top: "16%",
              }}
            >
              About Us
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: { xs: 18, md: 25 },
          padding: { xs: "0px 20px", md: "30px 170px" },
          textAlign: { md: "center" },
        }}
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          color: "#949494",
          marginTop: 30,
        }}
      >
        Welcome to our new online scrub shop! We offer a wide selection of
        high-quality scrubs in various colors, styles, and sizes. Our website
        provides a seamless shopping experience, with fast shipping and
        hassle-free returns. We pride ourselves on exceptional customer service
        and competitive prices. Thank you for choosing us as your go-to
        destination for all your scrub needs.
      </Typography>
      <TextWithDifferentColors
        firstText="Our mission"
        firstTextColor="#4AC1B7"
        secondText=" is to provide medical professionals with high-quality scrubs that are
          both comfortable and functional. We aim to make the scrub shopping
          experience as easy and convenient as possible, with a focus on
          exceptional customer service."
        secondTextColor="#949494"
      />

      <TextWithDifferentColors
        firstText="Our vision"
        firstTextColor="#4AC1B7"
        secondText=" is to become the leading online destination for medical professionals
          seeking high-quality scrubs. We strive to continuously expand our
          selection of scrubs and provide a seamless shopping experience that
          meets the needs of our customers. We are committed to delivering the
          best customer service, ensuring that every customer feels valued and
          satisfied with their purchase."
        secondTextColor="#949494"
      />
    </>
  );
};
export default AboutUsPage;
