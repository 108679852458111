import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import axios from "axios";
import { API_URL } from "../../../api";
import { useNavigate } from "react-router-dom";

export default function CheckoutForm({ price, order_values }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    if (error) {
      setErrorMessage("Transaction Failed!");
      setLoading(false);
    } else {
      const values = order_values;
      axios
        .post(`${API_URL}/orders/`, values)
        .then((response) => {
          setLoading(false);
          localStorage.setItem("cart", []);
          localStorage.setItem("confirmedOrder", JSON.stringify(response.data));
          localStorage.setItem("promocode_id", null);
          localStorage.setItem("shippingFees", 0);
          localStorage.setItem("totalPrice", 0);
          return navigate("/confirmedOrder");
        })
        .catch((e) => {
          setLoading(false);
          setErrorMessage("Failed to create order");
        });
    }
  };
  return (
    <Container component="main" maxWidth="sm" style={{ marginTop: 20 }}>
      <PaymentElement />
      <Button
        disabled={!stripe}
        style={{
          backgroundColor: "black",
          padding: 15,
          color: "white",
          marginTop: 20,
          borderColor: "black",
        }}
        fullWidth
        onClick={() => handleSubmit()}
      >
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress style={{ color: "white" }} />
          </Box>
        ) : (
          `PAY EGP${price}`
        )}
      </Button>
      {errorMessage && (
        <Typography style={{ color: "#DF1B41", paddingLeft: 15, fontSize: 14 }}>
          {errorMessage}
        </Typography>
      )}
    </Container>
  );
}
