import Footer from "../Footer";
import Navbar from "../Navbar";
import { Outlet, useNavigate } from "react-router";
import { useEffect } from "react";
import FloatingWhatsAppButton from "../WhatsappButton/WhatsappButton";
import FloatingMessengerButton from "../MessangerButton";

const Layout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <div style={{ flex: 1 }}>
        <Navbar />
        <FloatingWhatsAppButton />
        <FloatingMessengerButton />
        <Outlet />
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
